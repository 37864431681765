import React, { useState } from 'react';
import './formElements.css';

const CheckBox = ({
	id, name, value, labelValue,
	disabled, checked, onChangeFunct, keyId,
	errorMessage, errorClassName,
	containerClass, className
}) => {
	const [ isChecked, setIsChecked ] = useState(checked);
	let checkedValue = (isChecked)?"checked":"";
	let disabledValue = (disabled)?"disabled":"";

	return(
		<div key={keyId} className={containerClass}>
			<label className={className}>
				<input
					type="checkbox"
					id={id}
					name={name}
					value={value}
					disabled={disabled}
					checked={isChecked}
					onChange={(e) => {
						setIsChecked(!isChecked);
						onChangeFunct(e.target.value);
					}}
					className={className}
				/>
				{labelValue}
			</label>
			<div className={errorClassName}>
				{errorMessage}
			</div>
		</div>
	);
}

export default CheckBox;
