import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MainNavigationControl.css';
import {
	logoutUser
} from '../../../lib/webserviceFunctions';
import ActionButton from '../../formElements/ActionButton';

const MainNavigationControl = ({isLoggedIn, setIsLoggedIn}) => {
	const navigate = useNavigate();
	const mainContainerClassName = 'container-control-main-navigation';
	const actionButtonClassName = "navigation-button";
	const buttonContainerClassName = "navigation-button-container";

	const handleClick = (path) => {
    navigate(path);
  }

	if(isLoggedIn){
		return (
			<div className={mainContainerClassName}>
				<div className={buttonContainerClassName}>
					<ActionButton display="Communication Reception" id="homeBtn" onClickFunction={()=>{ handleClick('/') }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
				</div>
				<div className={buttonContainerClassName}>
					<ActionButton display="Community Interaction" id="communityBtn" onClickFunction={()=>{ handleClick('/community') }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
				</div>
				<div className={buttonContainerClassName}>
					<ActionButton display="Content Consumption" id="coursesBtn" onClickFunction={()=>{ handleClick('/courses') }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
				</div>
				<div className={buttonContainerClassName}>
					<ActionButton display="Collectibles Acquisition" id="storeBtn" onClickFunction={()=>{ handleClick('/store') }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
				</div>
			</div>
		);
	} else {
		return (
			<div className={mainContainerClassName}>
				<h3> Please log in </h3>
			</div>
		);
	}
}

export default MainNavigationControl;
