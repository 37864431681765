import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../formElements/ActionButton';
import CheckBoxGroup from '../formElements/CheckBoxGroup';
import {
	logoutUser,
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	clearStorage
} from '../../lib/helperFunctions';

const CommunitySubscription = ( { show, setShow, token, rebuildDropDown } ) => {
	const navigate = useNavigate();
	const [ communityList, loadCommunityList ] = useState([]);
	const [ checkboxes, setCheckboxes ] = useState([]);
	const user = JSON.parse(sessionStorage.getItem("DMBS"))?.user;
	const communitySubscriptionContainerClassName = 'container-community-subscription';
	const communitySubscriptionTitleClassName = 'community-subscription-title';
	const communitySubscribeButtonContainerClassName = "community-subscription-subscribe-button-container";
	const communitySubscribeCloseButtonContainerClassName = "community-subscription-close-button-container";
	const subscribeCommunitySubscribeButtonClassName = "button-action button-community-subscribe";
	const closeCommunitySubscribeButtonClassName = "button-action button-community-subscribe-close";
	const retrieveCommunityList = () => {
		callWebServicePost({sessionId:token,userLevel:user?.userLevel}, 'read/communities', (communities) => {
			loadCommunityList(communities);
		});
	};
	const adjustSubscription = () => {
		let name = "communities";
		let cbValues = [];
		let newCommList = [];
		document.getElementsByName(name).forEach((cb)=>{
			if(cb.checked){
				cbValues.push(cb.value);
			}
		});
		communityList.forEach((comm) => {
			if(cbValues.includes(comm._id)){
				newCommList.push(comm);
			}
		});
		updateUser(newCommList);
		rebuildDropDown(newCommList);
	};
	const updateUser = (commList) => {
		let userCommList = [];
		commList.forEach((comm)=>{
			userCommList.push({_id:comm._id, title:comm.title});
		});
		updateUserDB(userCommList);
		updateUserSessionStorage(userCommList);
	};
	const updateUserDB = (commList) => {
		let values = {sessionId:token, communities:commList}
		let path = 'update/user';
		callWebServicePost(values, path, (results) => {
			retrieveCommunityList();
			if(results.message.action === "LOGOUT"){
				logoutUser(token);
				clearStorage();
				navigate('/');
			}
		});
	};
	const updateUserSessionStorage = (commList) => {
		let DMBS = JSON.parse(sessionStorage.getItem('DMBS'));
		DMBS.user.communities = commList;
		sessionStorage.setItem('DMBS', JSON.stringify(DMBS));
	};
	const userIsSubscribed = (id) => {
		if(Array.isArray(user?.communities)){
			for(let index=0; index<user.communities.length; index++){
				if(id === user.communities[index]?._id){
					return true;
				}
			}
		}
		return false;
	}
	const buildCheckBoxGroup = () => {
		let cbArray = [];
		communityList.forEach((comm) => {
			let checked = userIsSubscribed(comm._id);
			cbArray.push({
				label:comm.title,
				value:comm._id,
				disabled:false,
				checked:checked
			});
		});
		setCheckboxes(cbArray);
	};

	useEffect(()=>{
		retrieveCommunityList();
	},[]);

	useEffect(()=>{
		buildCheckBoxGroup();
	},[communityList]);

	let returnCode = <></>
	if(show){
		returnCode = <div className={communitySubscriptionContainerClassName}>
			<div className={communitySubscriptionTitleClassName}>Choose Your Communities</div>
			<CheckBoxGroup
				id='communityCBGroup'
				name='communities'
				boxes={checkboxes}
				legend={false}
				legendLabel=''
				legendClass=''
				onChangeFunct={
					(val)=>{
						// Do Nothing
					}
				}/>
			<div name="community-subscription-subscribe-button-container" className={communitySubscribeButtonContainerClassName}>
				<ActionButton
					display="Subscribe"
					id="subscribeBtn_commSubscription"
					onClickFunction={()=>{
						adjustSubscription();
						setShow(false);
					}}
					buttonClassName={subscribeCommunitySubscribeButtonClassName}
					containerClassName="container-col" />
			</div>
			<div name="community-subscription-close-button-container" className={communitySubscribeCloseButtonContainerClassName}>
				<ActionButton
					display="Close"
					id="modalCloseBtn_commSubscription"
					onClickFunction={()=>{
						setShow(false);
					}}
					buttonClassName={closeCommunitySubscribeButtonClassName}
					containerClassName="container-col" />
			</div>
		</div>
	}
	return(returnCode);
};

export default CommunitySubscription;
