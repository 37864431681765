import React from 'react';
import ActionButton from '../formElements/ActionButton';

const Likes = ({ likes, likeId, toggleLike, containerClass, likeButtonClassName, hideButton }) => {
	const mainContainerClassName = 'page-element-likes-container';
	const buttonClassName = 'button-action page-element-button';
	let count = (likes && Array.isArray(likes))?likes.length:0;
	let buttonId = 'likeButton_' + likeId;
	let likeButton = (!hideButton)?
		<div>
			<ActionButton
				display="Like"
				id={buttonId}
				onClickFunction={
					()=>{
						toggleLike();
					}
				}
				buttonClassName={buttonClassName + " " + likeButtonClassName}
				containerClassName="container-col"
			/>
		</div>
	:<></>;

	let returnCode = <div className={mainContainerClassName + " " + containerClass}>
		<div>Likes: {count}</div>
		{likeButton}
	</div>;
	return(returnCode);
}

export default Likes;
