import React, { useState, useEffect } from 'react';
import Conversation from './Conversation';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';

const ConversationList = ({ token, permissions, selectedCommunity, selectedPage, sortOrder, pageLimit, setTotalCount }) => {
	const [ conversationArray, setConversationArray ] = useState([]);
	const userObj = JSON.parse(sessionStorage.getItem('DMBS'))?.user;
	const conversationListContainerClassName = 'container-conversation-list-component';

	useEffect(()=>{
		if(selectedCommunity !== '-1'){
			getConversationsFromDataBase();
		} else {
			buildConversations({totalCount:0,conversations:[]});
		}
	},[selectedCommunity, selectedPage, sortOrder, pageLimit]);

	const getConversationsFromDataBase = () => {
		buildConversations({totalCount:0,conversations:[]});
		let parameters = {};
		parameters['sessionId'] = token;
		parameters['communityId'] = selectedCommunity;
		parameters['sort'] = sortOrder;
		parameters['limit'] = pageLimit;
		parameters['skip'] = selectedPage;
		callWebServicePost(parameters, 'read/conversations', (results) => {
			buildConversations(results);
		});
	};
	const buildConversations = (results) => {
		setTotalCount(results?.totalCount)
		let conversations = results?.conversations;
		let tempArray = [];
		conversations.forEach((conversation)=>{
			let keyValue = `${selectedCommunity}_${conversation._id}`;
			tempArray.push(<Conversation keyValue={keyValue} conversation={conversation} token={token} permissions={permissions} userObj={userObj} refreshConversations={getConversationsFromDataBase} />);
		});
		setConversationArray(tempArray);
	};

	let returnCode = <></>;
	returnCode = <div className={conversationListContainerClassName}>
		<div>
			{conversationArray}
		</div>
	</div>;

	return(returnCode);
}

export default ConversationList;
