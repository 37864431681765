import React from 'react';
import TextInput from '../formElements/TextInput';
import {
	handleTitleChange,
	handleFirstNameChange,
	handleMiddleNameChange,
	handleLastNameChange,
	handleSuffixChange
} from '../../lib/formElementHelperFunctions';

export default function Name({
  titleObj,     setTitleObj,
  firstNameObj, setFirstNameObj,
  middleNameObj,setMiddleNameObj,
  lastNameObj,  setLastNameObj,
  suffixObj,    setSuffixObj
}) {
  const mainContainerClassName = 'container-name-object';
  const titleItemContainerClassName = 'form-item-container';
  const titleLabelClassName = 'form-title';
  const firstItemContainerClassName = 'form-item-container';
  const firstLabelClassName = 'form-title';
  const middleItemContainerClassName = 'form-item-container';
  const middleLabelClassName = 'form-title';
  const lastItemContainerClassName = 'form-item-container';
  const lastLabelClassName = 'form-title';
  const suffixItemContainerClassName = 'form-item-container';
  const suffixLabelClassName = 'form-title';
  const asteriskClassName = 'required-item';

  return(
    <div className={mainContainerClassName}>
      <div className={titleItemContainerClassName}>
        <div className={titleLabelClassName}>
          <label htmlFor="title"> Title: </label>
        </div>
        <TextInput
          id="title"
          type="text"
          placeholder="Mr./Mrs."
          inputClassName='soft-border shadow-bottom-right'
          value={titleObj.value}
          size='10'
          errorMessage={titleObj.errorMessage}
          errorClassName={titleObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleTitleChange(e.target.value, titleObj, (obj)=>setTitleObj(obj))}
        />
      </div>
      <div className={firstItemContainerClassName}>
        <div className={firstLabelClassName}>
          <label htmlFor="firstName"> <span className={asteriskClassName} >*</span> First Name: </label>
        </div>
        <TextInput
          id="firstName"
          placeholder="first name"
          inputClassName='soft-border shadow-bottom-right'
          value={firstNameObj.value}
          size='25'
          errorMessage={firstNameObj.errorMessage}
          errorClassName={firstNameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleFirstNameChange(e.target.value, firstNameObj, (obj)=>setFirstNameObj(obj))}
        />
      </div>
      <div className={middleItemContainerClassName}>
        <div className={middleLabelClassName}>
          <label htmlFor="middleName"> Middle Name: </label>
        </div>
        <TextInput
          id="middleName"
          placeholder="middle name"
          inputClassName='soft-border shadow-bottom-right'
          value={middleNameObj.value}
          size='25'
          errorMessage={middleNameObj.errorMessage}
          errorClassName={middleNameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleMiddleNameChange(e.target.value, middleNameObj, (obj)=>setMiddleNameObj(obj))}
        />
      </div>
      <div className={lastItemContainerClassName}>
        <div className={lastLabelClassName}>
          <label htmlFor="lastName"> <span className={asteriskClassName} >*</span> Last Name: </label>
        </div>
        <TextInput
          id="lastName"
          placeholder="last name"
          inputClassName='soft-border shadow-bottom-right'
          value={lastNameObj.value}
          size='25'
          errorMessage={lastNameObj.errorMessage}
          errorClassName={lastNameObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleLastNameChange(e.target.value, lastNameObj, (obj)=>setLastNameObj(obj))}
        />
      </div>
      <div className={suffixItemContainerClassName}>
        <div className={suffixLabelClassName}>
          <label htmlFor="suffix"> Suffix: </label>
        </div>
        <TextInput
          id="suffix"
          placeholder="III"
          inputClassName='soft-border shadow-bottom-right'
          value={suffixObj.value}
          size='10'
          errorMessage={suffixObj.errorMessage}
          errorClassName={suffixObj.errorClassName}
          containerClass="form-data"
          onChangeFunct={(e) => handleSuffixChange(e.target.value, suffixObj, (obj)=>setSuffixObj(obj))}
        />
      </div>
    </div>
  );
}
