import React from 'react';
import SelectOption from './SelectOption'
import './formElements.css';

export default function SelectInput({ values, selectedValue, id, name, size, multiple, blank, blankValue, blankDisplay, onChangeFunct, selectClassName, optionClassName, containerClass }){
	const buildOptions = () => {
		let options = [];
		if(blank){
			options.push(
				<SelectOption key="-1"  value={blankValue} displayValue={blankDisplay} optionClassName={optionClassName} />
			);
		}
		if(values && Array.isArray(values)){
			values.forEach((item, index, arr)=>{
				options.push(
					<SelectOption key={item.name+index}  value={item.value} displayValue={item.name} optionClassName={optionClassName} />
				);
			});
		}
		return options;
	}
	// buildOption();
	return(
		<div className={containerClass}>
			<select
				id={id}
				name={name}
				value={selectedValue}
				onChange={onChangeFunct}
				className={selectClassName}
    		size={size}
				multiple={multiple}
				>
				{
					buildOptions()
			  }
			</select>
		</div>
	);
}
