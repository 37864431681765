import React, {useState, useEffect} from 'react';
import Rules from './Rules';
import CommunitySubscription from './CommunitySubscription';
import ActionButton from '../formElements/ActionButton';
import SelectInput from '../formElements/SelectInput';
import RecordLimitSelector from '../pageElements/RecordLimitSelector';
import RecordSortSelector from '../pageElements/RecordSortSelector';
import RecordPaginationControl from '../pageElements/RecordPaginationControl';
import ConversationForm from './ConversationForm';
import ConversationList from './ConversationList';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import './Community.css';

const Community = ( {isLoggedIn, setIsLoggedIn} ) => {
	const [ show, setShow ] = useState(false);
	const [ showCommunitySubscription, setShowCommunitySubscription ] = useState(false);
	const [ showAddConversation, setShowAddConversation ] = useState(false);
	const [ conversationInfo, setConversationInfo ] = useState({
		title:{
			value: '',
			errorMessage: '',
			errorClassName: 'no-error',
			valid:false
		},
		text:{
			value: '',
			errorMessage: '',
			errorClassName: 'no-error',
			valid:false
		}
	});
	const [ permissions, setPermissions ] = useState({
		canAddRule:false,
		canUpdRule:false,
		canDelRule:false,
		canAddConversation:false,
		canUpdConversation:false,
		canDelConversation:false
	});
	const [ communityList, setCommunityList ] = useState([{}]);
	const [ selectedCommunity, setSelectedCommunity ] = useState(-1);
	const [ addConversationButton, setAddConversationButton ] = useState(<></>);
	const [ conversationPage, setConversationPage ] = useState(1);
	const [ conversationLimit, setConversationLimit ] = useState(10);
	const [ conversationSort, setConversationSort ] = useState('newest');
	const [ conversationCount, setConversationCount ] = useState(0);
	const containerCommunityClassName = "container-community";
	const containerCommunityControlsClassName = "container-community-controls";
	const showRulesButtonClassName = "button-action button-show-rules";
	const showCommunitySubscribeButtonClassName = "button-action button-show-subscribe";
	const communitySelectClassName="community-selector-style"
	const communityOptionClassName="community-selector-item-style"
	const communitySelectContainerClass="community-selector-container-style"
	const conversationListContainerClassName = "container-community-conversation-list";
	const recordPaginationControlClassName = 'container-community-record-pagination-control';
	const showAddConversationButtonClassName='button-action community-conversation-add-button';
	const addConversationButtonContainerClassName='container-col';
	const token = JSON.parse(sessionStorage.getItem("DMBS"))?.token;
	const populateCommunityListDropDown = (communities) => {
			let communityListArray = [];
			communities.forEach((community, index) => {
				communityListArray.push({name:community.title,value:community._id});
			});
			setCommunityList(communityListArray);
	};
	const getConversations = (selComm) => {
		setSelectedCommunity(selComm);
	}

	useEffect(()=>{
		try{
			let user = JSON.parse(sessionStorage.getItem("DMBS"))?.user;
			populateCommunityListDropDown(user.communities);
			callWebServicePost({userType:user?.userType}, 'read/permissions/communityPermissions', (commPermissions) => {
				if(commPermissions){
					sessionStorage.setItem('permissions',JSON.stringify(commPermissions));
					setPermissions(commPermissions);
				}
			});
		} catch(e) {

		}
	},[]);


	useEffect(()=>{
		if(!showAddConversation){
			setConversationInfo(
				{
					title:{
						value: '',
						errorMessage: '',
						errorClassName: 'no-error',
						valid:false
					},
					text:{
						value: '',
						errorMessage: '',
						errorClassName: 'no-error',
						valid:false
					}
				}
			);
		}
	},[showAddConversation]);

	useEffect(()=>{
		if(permissions.canAddConversation && selectedCommunity !== -1){
			setAddConversationButton(<ActionButton display="Add Conversation" id="addConversationBtn" onClickFunction={()=>{ setShowAddConversation(true) }} buttonClassName={showAddConversationButtonClassName} containerClassName={addConversationButtonContainerClassName} />);
		}
		setConversationPage(1);
	},[selectedCommunity]);


	const addConversationForm = (showAddConversation)?<ConversationForm
		token={token}
		selectedCommunity={selectedCommunity}
		getConversations={getConversations}
		conversationInfo={conversationInfo}
		setConversationInfo={setConversationInfo}
		setShowAddConversation={setShowAddConversation}
	/>:<></>;

	let returnCode =<div className={containerCommunityClassName}>
		<div className={containerCommunityControlsClassName}>
			<SelectInput
				values={communityList}
				selectedValue={selectedCommunity}
				id="communitiesDropDown"
				name="communitiesDropDown"
				size="1"
				blank={true}
				blankValue={-1}
				blankDisplay="Select a community"
				multiple={false}
				onChangeFunct={(e)=>{
					if(e.target.value === "-1"){
						setSelectedCommunity(-1);
					} else {
						setSelectedCommunity(e.target.value);
					}
				}}
				selectClassName={communitySelectClassName}
				optionClassName={communityOptionClassName}
				containerClass={communitySelectContainerClass}
			/>
			<ActionButton display="Subscribe" id="showCommunitySubscriptionBtn" onClickFunction={()=>{ setShowCommunitySubscription(true) }} buttonClassName={showCommunitySubscribeButtonClassName} containerClassName="container-col" />
			<RecordLimitSelector setDefaultValue={setConversationLimit} />
			<RecordSortSelector setDefaultValue={setConversationSort} />
			<RecordPaginationControl currentPage={conversationPage} setCurrentPage={setConversationPage} limitValue={conversationLimit} maxValue={conversationCount} containerClass={recordPaginationControlClassName} />
			<ActionButton display="Show Rules" id="showRulesBtn" onClickFunction={()=>{ setShow(true) }} buttonClassName={showRulesButtonClassName} containerClassName="container-col" />
		</div>
		{addConversationButton}
		{addConversationForm}
		<Rules show={show} setShow={setShow} canAddRule={permissions.canAddRule} canUpdRule={permissions.canUpdRule} canDelRule={permissions.canDelRule} />
		<CommunitySubscription show={showCommunitySubscription} setShow={setShowCommunitySubscription} rebuildDropDown={populateCommunityListDropDown} token={token} />
		<div className={conversationListContainerClassName}>
			<ConversationList token={token} permissions={permissions} selectedCommunity={selectedCommunity} selectedPage={conversationPage} sortOrder={conversationSort} pageLimit={conversationLimit} setTotalCount={setConversationCount} />
		</div>
	</div>;

	return (returnCode);

};

export default Community;
