import React from 'react';
import './formElements.css';

export default function RadioButton({ id, value, displayValue, selectedValue, radioName, radioClassName, disabled, onChange }){
	let checked = (value===selectedValue)?' checked ':'';
	
	return(
		<div className={radioClassName}>
			<input
				type="radio"
				id={id}
				name={radioName}
				disabled={disabled}
				checked={checked}
				value={value}
				onChange={onChange} />
			<label for={id}>{displayValue}</label>
		</div>
	);

}
