import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Body from './components/Body/Body'
import Login from './components/Login/Login';
import Profile from './components/Profile/Profile';
import PasswordReset from './components/Profile/PasswordReset';
import Community from './components/Community/Community';
import Courses from './components/Courses/Courses';
import Store from './components/Store/Store';
import BibleStudyRequestForm from './components/Body/BibleStudyRequestForm';

function App() {
  const clearStorage = () => {
		sessionStorage.clear();
		localStorage.clear();
	}
  const checkSession = () => {
    let dmbs = sessionStorage.getItem("DMBS");
    if(dmbs === undefined || dmbs === "undefined" || !dmbs){
      clearStorage();
      return false;
    }
    try{
      dmbs = JSON.parse(dmbs);
    } catch(e) {
      dmbs = null;
    }
    if(!dmbs){
      clearStorage();
      return false;
    }
  	const expires = dmbs?.expires;
    if(!expires){
      clearStorage();
      return false;
    }
    let sessionDateTime = new Date(Date.parse(expires));
		let currentDateTime = new Date(Date.now());
		let datetimeDifference = sessionDateTime-currentDateTime;
		if(datetimeDifference < 0){
      clearStorage();
      return false;
		}
    return true;
  }
  const [ isLoggedIn, setIsLoggedIn ] = useState(checkSession());

  return (
    <BrowserRouter>
    <div className="main-content">
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="App">
        <div className="demo-bg"></div>
        <div className="demo-content">
      <Routes>

        <Route path="/" element={<Body isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />

        <Route path='/loginForm' element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/profileForm' element={<Profile setIsLoggedIn={setIsLoggedIn} stepValue={(isLoggedIn)?"profileInfo":"personalInfo"} />} />
        <Route path='/passwordForm' element={<PasswordReset setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/community' element={<Community isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/courses' element={<Courses isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/store' element={<Store isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/request' element={<BibleStudyRequestForm isLoggedIn={isLoggedIn} />} />

      </Routes>
        </div>
      </div>
      <Footer isLoggedIn={isLoggedIn} />
    </div>
    </BrowserRouter>
  );
}
export default App;
