import React from 'react';
import './Header.css';
import {

} from '../../lib/webserviceFunctions';
import UserManagementControl from './UserManagementControl/UserManagementControl';
import MainNavigationControl from './MainNavigationControl/MainNavigationControl';
import BibleStudyNavigationControl from './BibleStudyNavigationControl/BibleStudyNavigationControl';

const Header = ({isLoggedIn, setIsLoggedIn}) => {
	const headerContainerClassName = 'container-header';
	const navigationContainerClassName = 'navigation';
	const navigationMainContainerClassName = 'navigation-main';
	const siteIdentifierContainerClassName = 'site-identifier';
	const navigationUserManContainerClassName = 'navigation-user-management';
	const middleSectionContainerClassName = 'container-header-middle-section';
	const bibleStudyNavContainerClassName = 'container-header-bible-study-request-button-container';
	const topHeaderBorder = 'border-header-top';
	const bottomHeaderBorder = 'border-header-bottom';
	const leftHeaderBorder = 'border-header-left';
	const rightHeaderBorder = 'border-header-right';
	const headerMiddleRow = 'container-header-middle-row';

	return (
		<div className={headerContainerClassName}>
			<div className={headerMiddleRow}>
				<div className={navigationContainerClassName}>
					<div className={navigationMainContainerClassName}>
						<MainNavigationControl isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
					</div>
					<div className={middleSectionContainerClassName}>
						<div className={siteIdentifierContainerClassName}> Disciple Maker Bible Study </div>
						<div className={bibleStudyNavContainerClassName}><BibleStudyNavigationControl isLoggedIn={isLoggedIn}/></div>
					</div>
					<div className={navigationUserManContainerClassName}>
						<UserManagementControl isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header;
