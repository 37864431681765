import React from 'react';
import TextInput from '../formElements/TextInput';
import {
	handleCountryCodeChange,
	handleAreaCodeChange,
	handleTelephonePrefixChange,
	handleLineNumberChange
} from '../../lib/formElementHelperFunctions';

export default function PhoneNumber({
  countryCodeObj, setCountryCodeObj,
  areaCodeObj, setAreaCodeObj,
  telephonePrefixObj, setTelephonePrefixObj,
  lineNumberObj, setLineNumberObj
}) {
  const mainContainerClassName = 'container-phone-number';
  const phoneItemContainerClassName = 'form-item-container';
  const phoneLabelClassName = 'form-title';
  const phoneDataClassName = 'form-data container-phone';
  const asteriskClassName = 'required-item';

  return(
    <div className={mainContainerClassName}>
      <div className={phoneItemContainerClassName}>
        <div className={phoneLabelClassName}>
          <label htmlFor="areaCode"> Phone Number: </label>
        </div>
        <div className={phoneDataClassName} >
          <div id='country-code-container'>
            <TextInput
              id="countryCode"
              placeholder=""
              inputClassName='text-align-right soft-border shadow-bottom-right'
              maxlength='7'
              size='4'
              value={countryCodeObj.value}
              errorMessage={countryCodeObj.errorMessage}
              errorClassName={countryCodeObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleCountryCodeChange(e.target.value, countryCodeObj, (obj)=>setCountryCodeObj(obj))}
            />
          </div>
          <div>
          &nbsp;
          </div>
          <div>
          (
          </div>
          <div id='area-code-container' >
            <TextInput
              id="areaCode"
              placeholder="123"
              inputClassName=' soft-border shadow-bottom-right'
              value={areaCodeObj.value}
              maxlength='3'
              size='3'
              errorMessage={areaCodeObj.errorMessage}
              errorClassName={areaCodeObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleAreaCodeChange(e.target.value, areaCodeObj, (obj)=>setAreaCodeObj(obj))}
            />
          </div>
          <div>
            )
          </div>
          <div id ='telephone-prefix-container'>
            <TextInput
              id="telephonePrefix"
              placeholder="456"
              inputClassName=' soft-border shadow-bottom-right'
              value={telephonePrefixObj.value}
              maxlength='3'
              size='3'
              errorMessage={telephonePrefixObj.errorMessage}
              errorClassName={telephonePrefixObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleTelephonePrefixChange(e.target.value, telephonePrefixObj, (obj)=>setTelephonePrefixObj(obj))}
            />
          </div>
          <div>
          -
          </div>
          <div id='line-number-container'>
            <TextInput
              id="lineNumber"
              placeholder="7890"
              inputClassName=' soft-border shadow-bottom-right'
              value={lineNumberObj.value}
              maxlength='4'
              size='4'
              errorMessage={lineNumberObj.errorMessage}
              errorClassName={lineNumberObj.errorClassName}
              containerClass=""
              onChangeFunct={(e) => handleLineNumberChange(e.target.value, lineNumberObj, (obj)=>setLineNumberObj(obj))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
