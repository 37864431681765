import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtonGroup from '../formElements/RadioButtonGroup';
import ActionButton from '../formElements/ActionButton';
import TextInput from '../formElements/TextInput';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';

const BibleStudyRequestForm = ({ isLoggedIn }) => {
	const [ broadTopic, setBroadTopic ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ format, setFormat ] = useState('article');
	const navigate = useNavigate();
	const containerClassName = 'container-bible-study-request-form';
	const loginMessageClassName = 'container-bible-study-request-login-message';
	const pageTitleClassName = 'bible-study-request-form-title';
	const pageContentContainerClassName = 'container-bible-study-request-content';
	const pageFormContainerClassName = 'container-bible-study-request-form';
	const pageActionContainerClassName = 'container-bible-study-request-action';
	const actionButtonDisplay = 'Submit';
	const actionButtonClassName = 'button-action submit-bible-study-request';
	const actionButtonContainerClassName = 'container-button-action-submit-bible-study-request';
	const actionButtonId = 'submitButtonBibleStudyRequest';
	const radioButtonGroupName = 'studyFormatRequest';
	const radioButtonGroupClassName = 'radiogroup-bible-study-request';
	const radioButtonRadioClassName = 'radioradio-bible-study-request';
	const inputTopicId = 'inputBibleStudyRequestTopic';
	const inputTopicType = 'text';
	const inputTopicPlaceHolder = 'Enter a Topic here.';
	const inputTopicInputClassName = 'soft-border shadow-bottom-right bible-study-request-topic';
	const inputTopicSize = '25';
	const inputTopicErrorMessage = '';
	const inputTopicErrorClassName = '';
	const inputTopicContainerClassName = 'form-data container-bible-study-request-topic';
	const inputDescId = 'inputBibleStudyRequestDescription';
	const inputDescType = 'text';
	const inputDescPlaceHolder = 'Enter a Description of your requested Bible study.';
	const inputDescInputClassName = 'soft-border shadow-bottom-right bible-study-request-desc';
	const inputDescSize = '25';
	const inputDescErrorMessage = '';
	const inputDescErrorClassName = '';
	const inputDescContainerClassName = 'form-data container-bible-study-request-desc';
	const values = [{
		id:'article',
		value:'Article',
		name:'article',
		label:'Article'
	},{
		id:'audio',
		value:'Audio',
		name:'audio',
		label:'Audio'
	},{
		id:'video',
		value:'Video',
		name:'video',
		label:'Video'
	},{
		id:'podcast',
		value:'Podcast',
		name:'podcast',
		label:'Podcast'
	},{
		id:'course',
		value:'Course',
		name:'course',
		label:'Course'
	},{
		id:'workbook',
		value:'Workbook',
		name:'workbook',
		label:'Workbook'
	},{
		id:'book',
		value:'Book',
		name:'book',
		label:'Book'
	},{
		id:'mappic',
		value:'Map/Picture',
		name:'mappic',
		label:'Map/Picture'
	}];

	const handleTopicChange = (value) => {
		setBroadTopic(value);
	}
	const handleDescChange = (value) => {
		setDescription(value);
	}
	const handleFormatChange = (value) => {
		setFormat(value);
	}
	const handleSubmitClicked = () => {
		let dmbsObj = JSON.parse(sessionStorage.getItem('DMBS'));
		let userObj = dmbsObj?.user;
		let token = dmbsObj?.token;
		let parameters = {};
		let path = 'create/studyRequest';
		parameters['format'] = format;
		parameters['topic'] = broadTopic;
		parameters['description'] = description;
		parameters['requestor'] = userObj?.email;
		parameters['token'] = token;

		callWebServicePost(parameters, path, (results) => {
			if(results?.acknowledged && results?.insertedId){
				alert('Thank you for requesting the following Bible study: ' + broadTopic + '- ' + description + ' in the ' + format + ' format.');
			}
		});

		navigate('/');
	}

	let returnCode = <div className={loginMessageClassName}>Please log in to request a Bible study.</div>

	if(isLoggedIn){
		returnCode = <div className={containerClassName}>
			<div className={pageTitleClassName}>Bible Study Request Form</div>
			<div className={pageContentContainerClassName}>
				<div className={pageFormContainerClassName}>
					<TextInput
						id={inputTopicId}
						type={inputTopicType}
						placeholder={inputTopicPlaceHolder}
						inputClassName={inputTopicInputClassName}
						value={broadTopic}
						size={inputTopicSize}
						errorMessage={inputTopicErrorMessage}
						errorClassName={inputTopicErrorClassName}
						containerClass={inputTopicContainerClassName}
						onChangeFunct={(e) => handleTopicChange(e.target.value)}
					/>
					<TextInput
						id={inputDescId}
						type={inputDescType}
						placeholder={inputDescPlaceHolder}
						inputClassName={inputDescInputClassName}
						value={description}
						size={inputDescSize}
						errorMessage={inputDescErrorMessage}
						errorClassName={inputDescErrorClassName}
						containerClass={inputDescContainerClassName}
						onChangeFunct={(e) => handleDescChange(e.target.value)}
					/>
					<RadioButtonGroup
						values={values}
						selectedValue={format}
						groupName={radioButtonGroupName}
						groupCSS={radioButtonGroupClassName}
						radioCSS={radioButtonRadioClassName}
						onChangeFunct={e => handleFormatChange(e.target.value)}
					/>
				</div>
				<div className={pageActionContainerClassName}>
					<ActionButton
						display={actionButtonDisplay}
						id={actionButtonId}
						onClickFunction={()=>{ handleSubmitClicked() }}
						buttonClassName={actionButtonClassName}
						containerClassName={actionButtonContainerClassName}
					/>
				</div>
			</div>
		</div>;
	}

	return(returnCode);
}

export default BibleStudyRequestForm;
