import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserManagementControl.css';
import {
	logoutUser
} from '../../../lib/webserviceFunctions';
import ActionButton from '../../formElements/ActionButton';

const UserManagementControl = ({isLoggedIn, setIsLoggedIn}) => {
	const navigate = useNavigate();
	const actionButtonClassName = "navigation-button";
	const userManagementControlContainerClassName = "container-control-user-management";
	const buttonContainerClassName = "container-control-user-management-button-container";
	const avatarContainerClassName = 'avatar';

	const clearStorage = () => {
		sessionStorage.clear();
		localStorage.clear();
	}


	const handleLogout = async () => {
		let token = "";
		let dmbs = {};
 		try{
			dmbs = sessionStorage.getItem('DMBS');
			let dmbsObj = JSON.parse(dmbs);
			token = dmbsObj?.token;
 		} catch(e) {}

 		try{
			clearStorage();
 		} catch(err){}

		try{
			const results = await logoutUser(token);
			sessionStorage.setItem('tempInfo',JSON.stringify(results));
		} catch(err){}

		if(setIsLoggedIn){
			setIsLoggedIn(false);
		}
		navigate('/');
	}

	const handleClick = (path) => {
    navigate(path);
  }

	if(isLoggedIn){
		return (
			<div className={userManagementControlContainerClassName}>

				<ActionButton display="LOGOUT" id="logoutBtn" onClickFunction={()=>{ handleLogout('/logout') }} buttonClassName={actionButtonClassName} containerClassName={buttonContainerClassName} />
				<ActionButton display="PROFILE" id="profileBtn" onClickFunction={()=>{ handleClick('/profileForm') }} buttonClassName={actionButtonClassName} containerClassName={buttonContainerClassName} />
				<ActionButton display="PASSWORD" id="passwordBtn" onClickFunction={()=>{ handleClick('/passwordForm') }} buttonClassName={actionButtonClassName} containerClassName={buttonContainerClassName} />
			</div>
		);
	} else {
		return (
			<div className={userManagementControlContainerClassName}>
				<ActionButton display="LOGIN" id="logoutBtn" onClickFunction={()=>{ handleClick('/loginForm') }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
			</div>
		);
	}
}

export default UserManagementControl;
