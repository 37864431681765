const getCreatedDate = () => {
        let now = new Date();
        let year = now.getFullYear();
        let month= (now.getMonth()<10)?`0${now.getMonth()}`:now.getMonth();
        let day  = now.getDate();
        return year + '-' + month + '-' + day;
}

const getCreatedDateTime = () => {
        let dateToday = new Date();
        let year = dateToday.getFullYear();
        let month = dateToday.getMonth() + 1;
        let day = dateToday.getDate();
        let hour = dateToday.getHours();
        let minute = dateToday.getMinutes();
        let second = dateToday.getSeconds();
        let millisecond = dateToday.getMilliseconds();
        let dateTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second + '.' + millisecond;
        return dateTime;
}

const createRandomString = (numOfChars, vals) => {
	const values = vals || '-_0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let randString = '';
	for(let i=0; i<numOfChars; i++){
		randString += values.substr(Math.floor(Math.random() * values.length),1);
	}
	return randString;
}
const getRandomLetters = (numOfChars) => {
  return createRandomString(numOfChars, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
}
const getRandomCapitalLetters = (numOfChars) => {
  return createRandomString(numOfChars, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
}
const getRandomLowerCaseLetters = (numOfChars) => {
  return createRandomString(numOfChars, 'abcdefghijklmnopqrstuvwxyz');
}
const getRandomNumbers = (numOfChars) => {
  return createRandomString(numOfChars, '0123456789');
}

const getFullName = (userName) => {
	if(!userName){
		return 'Mr. Smith'
	}
	let fullName = userName.title + " " + userName.firstName + " " + userName.middleName + " " + userName.lastName + ` ${userName.suffix}`;
	return fullName.trim();
}

const clearStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
}

const isObject = (objValue) => {
  return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}

module.exports = {
  getCreatedDate,
  getCreatedDateTime,
  createRandomString,
  getRandomLetters,
  getRandomCapitalLetters,
  getRandomLowerCaseLetters,
  getRandomNumbers,
  getFullName,
  clearStorage,
  isObject
}
