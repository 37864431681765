import React from 'react';
import './formElements.css';

export default function ActionButton({ display, id, onClickFunction, buttonClassName, containerClassName }){
	return(
		<div className={containerClassName}>
			<div
				id={id}
				name={id}
				onClick={onClickFunction}
				className={buttonClassName}
				>
				{display}
			</div>
		</div>
	);
}
