import React, { useState, useEffect } from 'react';
import SelectInput from '../formElements/SelectInput';

const RecordLimitSelector = ({ defaultValue, setDefaultValue, listArray, containerClass }) => {
	const [ value, setValue ] = useState((defaultValue)?defaultValue:10);

	useEffect(()=>{
		setDefaultValue(value);
	},[value]);

	const selectorArray = [
		{name:5,value:5},
		{name:10,value:10},
		{name:20,value:20},
		{name:30,value:30},
		{name:40,value:40},
		{name:50,value:50},
		{name:75,value:75},
		{name:100,value:100}
	];

	let containerClassName = `limit-selector ${containerClass}`;
	let limitList = (listArray && Array.isArray(listArray))?listArray:selectorArray;
	let controlCode = <>
	<SelectInput
		values={limitList}
		selectedValue={value}
		id="limitSelector"
		name="limitSelector"
		size="1"
		blank={false}
		blankValue={-1}
		blankDisplay=""
		multiple={false}
		onChangeFunct={(e)=>{
			setValue(parseInt(e.target.value));
		}}
		selectClassName="limit-selector-style"
		optionClassName="limit-selector-item-style"
		containerClass="limit-selector-container-style"
	/>
	</>;
	let returnCode = <div className={containerClassName}>{controlCode}</div>;
	return(returnCode);
}

export default RecordLimitSelector;
