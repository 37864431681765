import React, { useState } from 'react';
import './formElements.css';
import CheckBox from './CheckBox';

const CheckBoxGroup = ({
	id, name, boxes,
	legend, legendLabel,
	legendClass, onChangeFunct
}) => {

	let checkBoxArray = [];
	boxes.forEach((box,ind)=> {
		let cbID = name + '-' + ind;
		let cbKeyID = name+box.label+ind;
		checkBoxArray.push(<CheckBox id={cbID} name={name} value={box.value} labelValue={box.label}
			disabled={box.disabled} checked={box.checked}
			onChangeFunct={
				(val)=>{
					onChangeFunct(val);
				}
			}
			keyId={cbKeyID}
			errorMessage={box.errorMessage} errorClassName={box.errorClassName}
			containerClass={box.containerClass} className={box.className} />);
	});

	let returnCode = (legend)
		?<fieldset className={legendClass}>
			<legend className={legendClass}>{legendLabel}</legend>
			{checkBoxArray}
		</fieldset>
		:<div>{checkBoxArray}</div>;

	return(returnCode);
}

export default CheckBoxGroup;
