import React, {useState, useEffect} from 'react';
import ActionButton from '../formElements/ActionButton';
import SelectInput from '../formElements/SelectInput';
import TextInput from '../formElements/TextInput';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import './Courses.css';

const Courses = ( {isLoggedIn, setIsLoggedIn} ) => {
	const mainContainerClassName = 'container-courses-main';
	return(<div className={mainContainerClassName}><h1>Courses</h1></div>);
}

export default Courses;
