import React, { useState, useEffect } from 'react';
import SelectInput from '../formElements/SelectInput';

const RecordSortSelector = ({ defaultValue, setDefaultValue, containerClass }) => {
	const [ value, setValue ] = useState('newest');

	useEffect(()=>{
		setDefaultValue(value);
	},[value]);

	const selectorArray = [
		{name:'Newest',value:'newest'},
		{name:'Oldest',value:'oldest'},
		{name:'Most Comments',value:'most'},
		{name:'Least Comments',value:'least'}
	];

	let containerClassName = `sort-selector ${containerClass}`;
	let sortList = selectorArray;
	let controlCode = <>
	<SelectInput
		values={sortList}
		selectedValue={value}
		id="sortSelector"
		name="sortSelector"
		size="1"
		blank={false}
		blankValue={-1}
		blankDisplay=""
		multiple={false}
		onChangeFunct={(e)=>{
			setValue(e.target.value);
		}}
		selectClassName="sort-selector-style"
		optionClassName="sort-selector-item-style"
		containerClass="sort-selector-container-style"
	/>
	</>;
	let returnCode = <div className={containerClassName}>{controlCode}</div>;
	return(returnCode);
}

export default RecordSortSelector;
