import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	handlePasswordChange
} from '../../lib/formElementHelperFunctions';
import {
	clearStorage
} from '../../lib/helperFunctions';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import TextInput from '../formElements/TextInput';
import ActionButton from '../formElements/ActionButton';

const PasswordReset = ({
	setIsLoggedIn
 }) => {
	const getStarterObject = (value) => {
		return {
			value: value,
			errorMessage: '',
			errorClassName: 'no-error',
			valid:false
		}
	}
	const [ passwordObj, 		setPasswordObj ] 		= useState({
		new:getStarterObject(""),
		con:getStarterObject("")
	});
	const [ submitDisabled, setSubmitDisabled ] = useState("button-disabled");
	const [ validPWD, 			setValidPWD ] 			= useState(false);
	const navigate = useNavigate();
	const actionButtonClassName = "button-action button-password-reset-submit";
	const cancelButtonClassName = "button-action button-password-reset-cancel";
	const mainContainerClassName = 'container-password-reset';
	const newPasswordContainerClassName = 'container-password-reset-new';
	const newPasswordLabelClassName = 'form-title';
	const confirmPasswordContainerClassName = 'container-password-reset-confirm';
	const confirmPasswordLabelClassName = 'form-title';
	const requiredNoteClassName = 'required-item';
	const asteriskClassName = 'required-item';
	const dmbs = JSON.parse(sessionStorage.getItem('DMBS'));

	const redirect = (path) => {
		navigate(path);
	}
	const setValidity = () => {
		setSubmitDisabled((passwordObj.new.valid && passwordObj.con.valid)?"":"button-disabled");
	};

	useEffect(()=>{
		setValidity();
	},[])
	useEffect(()=>{
		setValidity();
	},[passwordObj]);

	const handleNewChange = (newVal) => {
		let newObj = passwordObj.new;
		let conObj = passwordObj.con;
		handlePasswordChange(newVal, newObj, (obj)=>{
			newObj = obj;
			if(newObj.value !== conObj.value){
				conObj.valid = false;
				conObj.errorMessage = 'Does not match.';
				conObj.errorClassName = 'text-input-error';
			} else {
				conObj.valid = true;
				conObj.errorMessage = '';
				conObj.errorClassName = 'no-error';
			}
			setPasswordObj({
				new: newObj,
				con: conObj
			});
		});
	};
	const handleConChange = (newVal) => {
		let newObj = passwordObj.new;
		let conObj = passwordObj.con;
		conObj.value = newVal;
		if(newObj.value !== conObj.value){
			conObj.valid = false;
			conObj.errorMessage = 'Does not match.';
			conObj.errorClassName = 'text-input-error';
		} else {
			conObj.valid = true;
			conObj.errorMessage = '';
			conObj.errorClassName = 'no-error';
		}
		setPasswordObj({
			new: newObj,
			con: conObj
		});
	};

	const cancelPasswordChange = () => {
		redirect('/');
	}

	const submitPasswordChange = () => {
		if(passwordObj.new.value.trim() === ""){
			alert('You must change your password to a value that meets the minimum standard.');
			return;
		}
		let dataSource = {
			sessionId: dmbs.token,
			password: passwordObj.new.value
		}
		callWebServicePost(dataSource, 'update/password', (results) => {
			redirect('/');
		});
	};

	if(!dmbs || !dmbs?.token){
		clearStorage();
		setIsLoggedIn(false);
		return(
			<h1>Somehow you have lost your session information. Please log in again.</h1>
		);
	}

  return (
    <div className={mainContainerClassName}>
		<div className={newPasswordContainerClassName}>
			<div className={newPasswordLabelClassName}>
				<label htmlFor="newpassword"> <span className={asteriskClassName} >*</span> New Password: </label>
			</div>
			<TextInput
				id="newpassword"
				type="password"
				placeholder="password"
				inputClassName='soft-border shadow-bottom-right'
				value={passwordObj.new.value}
				size='25'
				errorMessage={passwordObj.new.errorMessage}
				errorClassName={passwordObj.new.errorClassName}
				containerClass="form-data"
				onChangeFunct={(e) => handleNewChange(e.target.value)}
			/>
		</div>
		<div className={confirmPasswordContainerClassName}>
			<div className={confirmPasswordLabelClassName}>
				<label htmlFor="newpasswordconfirm"> <span className={asteriskClassName} >*</span> Confirm New Password: </label>
			</div>
			<TextInput
				id="newpasswordconfirm"
				type="password"
				placeholder="password"
				inputClassName='soft-border shadow-bottom-right'
				value={passwordObj.con.value}
				size='25'
				errorMessage={passwordObj.con.errorMessage}
				errorClassName={passwordObj.con.errorClassName}
				containerClass="form-data"
				onChangeFunct={(e) => handleConChange(e.target.value)}
			/>
		</div>
		<div className="container-row">
			<ActionButton display="Cancel" id="passwordResetCancelBtn" onClickFunction={()=>{ cancelPasswordChange() }} buttonClassName={cancelButtonClassName} containerClassName="container-col" />
			<ActionButton display="Change Password" id="passwordResetSubmitBtn" onClickFunction={()=>{ submitPasswordChange() }} buttonClassName={actionButtonClassName + " " + submitDisabled} containerClassName="container-col" />
		</div>
		<h5 className={requiredNoteClassName}> * indicates required field </h5>
    </div>
  );
}

export default PasswordReset;
