import React from 'react';
import Reply from './Reply';
import ActionButton from '../formElements/ActionButton';

const ReplyList = ({ conversationId, comment, setShowReplies }) => {
	const containerClassName = "container-replies-list";
	const hideRepliesButtonClassName = "button-action button-replies-hide";
	let returnCode = <></>;
	let replies = [];

	comment.replies.forEach((reply) => {
		if(!reply.deleted){
			replies.push(<Reply reply={reply} comment={comment} conversationId={conversationId} />);
		}
	});

	returnCode = <div className={containerClassName}>
		{replies}
		<ActionButton display="Hide Replies" id={`hideRepliesBtn_${comment._id}`} onClickFunction={()=>{
			setShowReplies(false);
		}} buttonClassName={hideRepliesButtonClassName} containerClassName="container-col" />
	</div>;

	return(returnCode);
};

export default ReplyList;
