import React, { useState } from 'react';
import ActionButton from '../formElements/ActionButton';
import TextInput from '../formElements/TextInput';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	handleRuleTextChanged,
	validateRequiredValue
} from '../../lib/formElementHelperFunctions';
import './Community.css';

const getNewObj = (value) => {
	let obj = {
		value: value,
		errorMessage: '',
		errorClassName: 'no-error',
		valid:true
	}
	return validateRequiredValue(value, obj);
}

const Rule = ( {sessionId, rule, canUpdRule, canDelRule, setRetrieved, buildChildren} ) => {
	const [ ruleTextObj, setRuleTextObj ] = useState(getNewObj(rule.text));
	const [ origTextObj, setOrigTextObj ] = useState(getNewObj(rule.text));
	const ruleContainerClassName = 'container-rule';
	const updateButtonClassName = "button-action button-rule-update";
	const deleteButtonClassName = "button-action button-rule-delete";
	const submitButtonClassName = (ruleTextObj.valid)?"button-action button-rule-update-submit":"button-action button-rule-update-submit button-disabled";
	const cancelButtonClassName = "button-action button-rule-update-cancel";
	const updateRuleButtonContainerClassName = "container-button-rule-update";
	const formRowContainerClassName = "container-form-rule-update display-none";
	const ruleUpdateFormOrderNumClassName = "rule-update-form-order-num";
	const deleteButtonContainerClassName = "container-button-rule-delete";
	const ruleInfoContainerClassName = "container-rule-info";
	const orderNumberDisplayClassName = "rule-order-num-display";
	const textDisplayClassName = "rule-text-display";
	let orderNumber = rule.orderNum;
	let ruleText = rule.text;
	let ruleID = rule._id;
	let updateButton = <></>;
	let deleteButton = <></>;
	let formRow = <></>;
	let formRowID = `rule_${ruleID}`;
	let displayRowID = `ruleDisplay_${orderNumber}`;
	let inputID = `updRuleText_${orderNumber}`;
	let submitID = `ruleSubmit_${orderNumber}`;
	let cancelID = `ruleCancel_${orderNumber}`;
	let deleteID = `ruleDelete_${orderNumber}`;

	if(canUpdRule){
		updateButton = <div name="rule-upd-button-container" className={updateRuleButtonContainerClassName}>
			<ActionButton
				display="Update"
				id="updRuleBtn"
				onClickFunction={()=>{
					document.getElementById(formRowID).classList.toggle("display-none");
					document.getElementById(displayRowID).classList.toggle("display-none");
				}}
				buttonClassName={updateButtonClassName}
				containerClassName="container-col" />
		</div>;

		formRow = <div id={formRowID} name="rule-update-form-container" className={formRowContainerClassName}>
			<div name="rule-update-form-order-num" className={ruleUpdateFormOrderNumClassName}>{orderNumber}</div>
			<TextInput
				id={inputID}
				placeholder={ruleTextObj.value}
				inputClassName='soft-border shadow-bottom-right'
				value={ruleTextObj.value}
				size='25'
				errorMessage={ruleTextObj.errorMessage}
				errorClassName={ruleTextObj.errorClassName}
				containerClass="form-data"
				onChangeFunct={ (e) => {
					handleRuleTextChanged(e.target.value, ruleTextObj, (obj)=>{
						setRuleTextObj(obj);
					});
				} }
			/>

			<ActionButton
				display="Submit"
				id={submitID}
				onClickFunction={()=>{
					if(ruleTextObj.valid){
						callWebServicePost({sessionId:sessionId,ruleID:ruleID,ruleText:ruleTextObj.value}, 'update/rule', (results) => {
							sessionStorage.setItem('updateResults', JSON.stringify(results));
							if(results.message.result.modifiedCount > 0){
								setOrigTextObj(ruleTextObj);
								setRetrieved(false);
							}
						});
						document.getElementById(formRowID).classList.toggle("display-none");
						document.getElementById(displayRowID).classList.toggle("display-none");
					} else {
						alert('The rule cannot be a blank rule. Please enter a new value or cancel your request.');
					}
				 }}
				buttonClassName={submitButtonClassName}
				containerClassName="container-col" />

			<ActionButton
				display="Cancel"
				id={cancelID}
				onClickFunction={()=>{
					document.getElementById(formRowID).classList.toggle("display-none");
					document.getElementById(displayRowID).classList.toggle("display-none");
					setRuleTextObj(origTextObj);
				 }}
				buttonClassName={cancelButtonClassName}
				containerClassName="container-col" />
		</div>;
	}

	if(canDelRule){
		deleteButton = <div name="rule-del-button-container" className={deleteButtonContainerClassName}>
			<ActionButton
				display="Delete"
				id={deleteID}
				onClickFunction={
					()=>{
						let text = "Are you sure you want to delete the following rule? \n" + orderNumber + ". " + ruleText;
					  if (window.confirm(text) === true) {
							callWebServicePost({sessionId:sessionId,ruleID:ruleID}, 'delete/rule', (results) => {
								sessionStorage.setItem('deleteResults', JSON.stringify(results));
								if(results.message.result.deletedCount > 0){
									sessionStorage.setItem('buildChildren', true);
									buildChildren();
								} else {
									sessionStorage.setItem('buildChildren', false);
								}
							});
					  }
					}
				}
				buttonClassName={deleteButtonClassName}
				containerClassName="container-col"
			/>
		</div>;
	}

	return(
		<div className={ruleContainerClassName}>
			<div id={displayRowID} className={ruleInfoContainerClassName}>
				<div name="rule-order-num" className={orderNumberDisplayClassName}>{orderNumber}</div>
				<div name="rule-text" className={textDisplayClassName}>{ruleText}</div>
				{updateButton}
				{deleteButton}
			</div>
			{formRow}
		</div>
	);
}


export default Rule;
