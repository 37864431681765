import React from 'react';
import RadioButton from './RadioButton'
import './formElements.css';

export default function RadioButtonGroup({ values, selectedValue, groupName, groupCSS, radioCSS, onChangeFunct }){
	const buildOptions = () => {
		let options = [];
		if(values){
			values.forEach((item, index, arr)=>{
				options.push(
					<RadioButton
						key={item.value+index}
						id={item.id}
						value={item.value}
						displayValue={item.label}
						selectedValue={selectedValue}
						radioName={groupName}
						radioClassName={radioCSS}
						disabled={item.disabled}
						onChange={onChangeFunct} />
				);
			});
		} else {
			options.push(<h1>{values}</h1>);
		}
		return options;
	}

	return(
		<div className={groupCSS}>
			{buildOptions()}
		</div>
	);
}
