import React, { useState, useEffect } from 'react';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	handleStudyChange
} from '../../lib/formElementHelperFunctions';
import RadioButtonGroup from '../formElements/RadioButtonGroup';

const StudySelection = ({ studySelectObj, setStudySelectObj }) => {
  const [ studySelectionObj, setStudySelectionObj ] = useState();
	const mainContainerClassName = 'container-study-selection';
  useEffect(()=>{
    loadStudyLevels();
  },[studySelectObj]);

  const getStudyOptions = (results) => {
    let returnArray = [];
    results.forEach((result, index, arr)=>{
      let studyLevel = {
        id:result.abbreviation,
        value:result.value,
				name:result.name,
        label:result.name + " - $" + result.monthlyCost + "/month",
				monthlyCost:result.monthlyCost,
				yearlyCost:result.yearlyCost,
        disabled:false
      };
      returnArray.push(studyLevel);
    });
    if(returnArray.length > 0){
      sessionStorage.setItem('levels', JSON.stringify(returnArray));
    }
    return returnArray;
  };

  const buildRadioButtonGroup = (values, selectedValue) => {
    return (
      <div className={mainContainerClassName}>
        <RadioButtonGroup
          values={values}
          selectedValue={selectedValue}
          groupName="studyOption"
          groupCSS=""
          radioCSS=""
          onChangeFunct={e => handleStudyChange(parseInt(e.target.value), studySelectObj, (obj) => setStudySelectObj(obj))}
          />
      </div>
    );
  }

  const loadStudyLevels = () => {
    let sessionStorageLevels = null;
    try {
      sessionStorageLevels = JSON.parse(sessionStorage.getItem('levels'));
    } catch(e) {}
    if(!sessionStorageLevels){
      callWebServicePost({key:'test'}, 'read/userLevels', (results) => {
        setStudySelectionObj(
          buildRadioButtonGroup(getStudyOptions(results), studySelectObj.value)
        );
      });
    } else {
      setStudySelectionObj(
        buildRadioButtonGroup(sessionStorageLevels, studySelectObj.value)
      );
    }
  };

  return (
    <>
      {
        studySelectionObj
      }
    </>
  );

}

export default StudySelection;
