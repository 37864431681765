import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import {
  loginUser
} from '../../lib/webserviceFunctions';
import {
  clearStorage
} from '../../lib/helperFunctions';

export default function Login({setIsLoggedIn}) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [messages, setMessages] = useState();
  const navigate = useNavigate();
  const loginContainerClassName = 'section-container login-wrapper';
  const containerCreateNewUserLinkClassName = 'create-new-user-link-container';
  const hrefLinkClassName = "href-link";
  const loginMessagesClassName = 'login-messages';
  const loginSubmitContainerClassName = 'container-button-submit-login';


  const redirect = (path) => {
    navigate(path);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const results = await loginUser({
      username,
      password
    });
    clearStorage();
    if(results.message?.text === "success"){
      sessionStorage.setItem('DMBS', JSON.stringify(results));
      if(setIsLoggedIn){
        setIsLoggedIn(true);
      }
      redirect('/');
    } else {
      sessionStorage.setItem('error', JSON.stringify(results));
      if(setIsLoggedIn){
        setIsLoggedIn(false);
        setMessages(results.message.text);
      }
    }
  }

  if(sessionStorage.getItem('DMBS')){
    return(
      <div className={loginContainerClassName}>
        <h1>ALREADY LOGGED IN!!</h1>
      </div>
    );
  }

  return(
    <div className={loginContainerClassName}>
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Email</p>
          <input name="email" id="email" type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input name="password" id="password" type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div className={loginSubmitContainerClassName}>
          <button type="submit">Submit</button>
        </div>
        <div className={containerCreateNewUserLinkClassName}>
          <div className={hrefLinkClassName} onClick={()=> redirect('/profileForm')}>Sign Up</div>
        </div>
				<div className={loginMessagesClassName}> {messages} </div>
      </form>
    </div>
  )
}
