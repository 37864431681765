export const loginUser = async(credentials) => {
	return await callWebServicePost(credentials, 'login');
}

export const logoutUser = async(token) => {
	return await callWebServiceGet(token, 'logout');
}

export const getIsEmailUniqueFromWebService = (emailAddress, callback) => {
	callWebServiceGet(emailAddress, 'isEmailUnique', callback);
}

const getRootURL = () => {
	if(process.env.REACT_APP_ROOT_URL){
		return process.env.REACT_APP_ROOT_URL;
	}
	return '';
}

export const callWebServicePost = async (dataObj, route, callBack) => {
	const fullURL = getRootURL() + '/' + route + '/';
	if(callBack){
		fetch(
		 	fullURL,
			{
				method: 'POST',
				mode: 'cors',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(dataObj)
			}
		).then(response => response.json()).then(data => callBack(data));
	} else {
		return fetch(
		 	fullURL,
			{
				method: 'POST',
				mode: 'cors',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(dataObj)
			}
		).then(data => data.json())
	}
}

export const callWebServiceGet = async (value, route, callBack) => {
	const fullURL = getRootURL() + '/' + route + '/' + value;
	if(callBack){
		fetch(
		 	fullURL,
			{
				method: 'GET',
				mode: 'cors'
			}
		).then(response => response.json()).then(data => callBack(data));
	} else {
		return fetch(
		 	fullURL,
			{
				method: 'GET',
				mode: 'cors'
			}
		).then(data => data.json())
	}
}
