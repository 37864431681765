import React, { useState } from 'react';
import TextInput from '../formElements/TextInput';
import ActionButton from '../formElements/ActionButton';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	validateRequiredValue
} from '../../lib/formElementHelperFunctions';
import {
	createRandomString
} from '../../lib/helperFunctions';
import './Community.css';

const getStarterObj = (comment) => {
	if(comment && comment.text !== ''){
		return {
			value: comment.text,
			errorMessage: '',
			errorClassName: 'no-error',
			valid:true
		}
	} else {
		return {
			value: '',
			errorMessage: '',
			errorClassName: 'no-error',
			valid:false
		};
	}
};

const CommentForm = ({ user, conversation, conversId, comment, token, setShowCommentForm, callBack }) => {
	const [ commentTextObj, setCommentTextObj ] = useState(getStarterObj(comment));
	const conversationId = (conversation?._id)?conversation._id:conversId;
	const commentFormId = (comment)?`conversation-comment-${comment._id}`:`conversation-comment-${conversationId}`;
	const commentFormSubmitButtonId = (comment)?`commentSubmit-${comment._id}`:`commentSubmit-${conversationId}`;
	const containerCommentFormClassName = 'container-comment-form';
	const submitButtonClassName = 'button-action button-comment-submit';
	const cancelButtonClassName = 'button-action button-comment-submit-cancel';
	const handleCommentTextChanged = (val, obj, callBack) => {
		callBack(validateRequiredValue(val, obj));
	};

	const buildId = () => {
		let comments = (conversation?.comments && Array.isArray(conversation.comments))?conversation.comments:[];
		let commentIDs = [];
		comments.forEach((comment) => {
			commentIDs.push(comment._id);
		});
		let newID = createRandomString(13);
		while(commentIDs.includes(newID)){
			newID = createRandomString(13);
		}
		return newID;
	}

	let submitButton = <ActionButton
		display="Submit"
		id={commentFormSubmitButtonId}
		onClickFunction={()=>{
			if(commentTextObj.valid){
				let path = (comment)?'update/comment':'create/comment';
				let commentObj = {};
				commentObj['_id'] = (comment?._id)?comment._id:buildId();
				commentObj['userName'] = (comment?.userName)?comment.userName:user.name.userName;
				commentObj['likes'] = (comment?.likes)?comment.likes:[];
				commentObj['replies'] = (comment?.replies)?comment.replies:[];
				commentObj['deleted'] = (comment?.deleted)?comment?.deleted:false;
				commentObj['text'] = commentTextObj.value;
				let dataObj = {};
				dataObj['token'] = token;
				dataObj['commentObj'] = commentObj;
				dataObj['conversationId'] = conversationId;
				callWebServicePost(dataObj, path, (results) => {
					if(Array.isArray(results)){
						for(let arrInd=0; arrInd<results.length; arrInd++){
							if(results[arrInd].message.result.modifiedCount > 0){
								if(callBack){
									callBack(commentObj);
								}
								setShowCommentForm(false);
							}
						}
					} else {
						if(results.message.result.modifiedCount > 0){
							setShowCommentForm(false);
						}
					}
				});
			} else {
				alert('The comment text cannot be blank. Please enter a value or cancel your request.');
			}
		 }}
		buttonClassName={submitButtonClassName}
		containerClassName="container-col" />;
	let cancelButton =
		<ActionButton display="Cancel" id="addCommentCancelBtn" onClickFunction={()=>{
			setShowCommentForm(false);
		}} buttonClassName={cancelButtonClassName} containerClassName="container-col" />;
	let textinput = <TextInput
		id={commentFormId}
		inputClassName='soft-border shadow-bottom-right'
		value={commentTextObj.value}
		size='25'
		errorMessage={commentTextObj.errorMessage}
		errorClassName={commentTextObj.errorClassName}
		containerClass="form-data"
		onChangeFunct={ (e) => {
			handleCommentTextChanged(e.target.value, commentTextObj, (obj)=>{
				setCommentTextObj(obj);
			});
		} }
	/>
	let returnCode = <div className={containerCommentFormClassName}>
		<div><label>Comment {textinput}</label></div>
		<div>{submitButton}</div>
		<div>{cancelButton}</div>
	</div>

	return(returnCode);
};

export default CommentForm;
