import React from 'react';
import './Modal.css';
import ActionButton from '../formElements/ActionButton';

const Modal = ( {show, setShow, children, modID, childrenContainerClassName} ) => {
	const showHideClassName = show ? "modal display-block" : "modal display-none";
	const modalID = `modalCloseBtn_${modID}`;
	const actionButtonClassName = "button-action button-modal";
	const closeButtonContainerClassName = 'modal-button-close-container';
	const sectionContainerClassName = "section-modal-main";
	let returnCode = <></>
	if(show){
		returnCode = <div name="modal-container" className={showHideClassName}>
				<section className={sectionContainerClassName}>
					<div name="modal-children-container" className={childrenContainerClassName}>
						{children}
					</div>
					<div name="modal-close-button-container" className={closeButtonContainerClassName}>
						<ActionButton display="Close" id={modalID} onClickFunction={()=>{ setShow(false) }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
					</div>
				</section>
			</div>;
	}

	return(returnCode);
}

export default Modal;
