import React, { useState, useEffect } from 'react';
import {
	handlePasswordChange
} from '../../lib/formElementHelperFunctions';
import TextInput from '../formElements/TextInput';

const PasswordInfo = ({
	passwordObj, setPasswordObj
 }) => {
	 const mainContainerClassName = 'container-password-info-main';
	 const formItemContainerClassName	= 'form-item-container';
	 const formTitleClassName = 'form-title';
	 const asteriskClassName = 'required-item';

  return (
    <>
		<div className={mainContainerClassName}>
			<div className={formItemContainerClassName} >
				<div className={formTitleClassName}>
					<label htmlFor="password"> <span className={asteriskClassName} >*</span> Password: </label>
				</div>
				<TextInput
					id="password"
					type="password"
					placeholder="password"
					inputClassName='soft-border shadow-bottom-right'
					value={passwordObj.value}
					size='25'
					errorMessage={passwordObj.errorMessage}
					errorClassName={passwordObj.errorClassName}
					containerClass="form-data"
					onChangeFunct={(e) => handlePasswordChange(e.target.value, passwordObj, (obj)=>{
						setPasswordObj(obj);
					})}
					autoComplete="new-password"
				/>
			</div>
		</div>
    </>
  );

}

export default PasswordInfo;
