import {
  getIsEmailUniqueFromWebService
} from './webserviceFunctions';

export const capitalizeFirstLetter = (val) => {
	if(val && val.trim() !== ''){
		let newVal = val.substr(0,1).toUpperCase();
		newVal += val.substr(1);
		return newVal;
	}
	return '';
}

export const valueIsEmpty = (val) => {
	if(!val){
		return true;
	} else if(val === undefined){
		return true;
	} else if(val.trim() === ''){
		return true;
	}
	return false;
}

export const validateInteger = (val, valObj) => {
	let obj = {
		valid: true
	}
  if(!valueIsInteger(val)){
    obj.value = val;
		obj.errorMessage = 'Value is not an Integer';
		obj.errorClassName = 'text-input-error';
		obj.valid = false;
  } else {
		obj.value = val;
		obj.errorMessage = '';
		obj.errorClassName = 'no-error';
	}
	return obj;
}

export const validateRequiredValue = (val, valObj) => {
	let obj = {
		valid: true
	}
	if(val.trim().length < 1){
		obj.value = '';
		obj.errorMessage = 'Value is Required';
		obj.errorClassName = 'text-input-error';
		obj.valid = false;
	} else {
		obj.value = val;
		obj.errorMessage = '';
		obj.errorClassName = 'no-error';
	}
	return obj;
}

export const getCreatedDateTime = () => {
  let dateToday = new Date();
  let year = dateToday.getFullYear();
  let month = dateToday.getMonth() + 1;
  let day = dateToday.getDate();
  let hour = dateToday.getHours();
  let minute = dateToday.getMinutes();
  let second = dateToday.getSeconds();
  let millisecond = dateToday.getMilliseconds();
  let dateTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second + '.' + millisecond;
  return dateTime;
}

export const emailIsInCorrectFormat = (email) => {
	var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regexp.test(email);
}

export const valueHasNoMoreThanOneDash = (val) => {
	let secondDashIndex = val.indexOf('-', val.indexOf('-')+1);
	if(secondDashIndex>=0){
		return false;
	}
	return true;
}

export const valueIsDash = (val) => {
	return val === '-';
}

export const valueIsNumber = (val) => {
	return !isNaN(val);
}

export const valueIsInteger = (val) => {
	return Number.isInteger(parseInt(val));
}

export const valueIsCorrectLength = (val, length) => {
	// if value less than length return -1
	// if value equals length return 0
	// if value more than length return 1
	if(val.trim().length < length){
		return -1;
	}
	if(val.trim().length > length) {
		return 1;
	}
	return 0;
}

export const valueHasSpecialCharacter = (val) => {
	var specRegExp = /(?=.*[!@#$%^&*()])/;
	return specRegExp.test(val);
}

export const valueHasCapitalLetter = (val) => {
	var capRegExp = /(?=.*[A-Z])/;
	return capRegExp.test(val);
}

export const valueHasLowercaseLetter = (val) => {
	var lowRegExp = /(?=.*[a-z])/;
	return lowRegExp.test(val);
}

export const valueHasNumeral = (val) => {
	var numRegExp = /(?=.*\d)/;
	return numRegExp.test(val);
}

export const valueHasSpaces = (val) => {
	var whiteSpaceRegExp = /(?=.*\s)/;
	return whiteSpaceRegExp.test(val);
}

export function setUnvalidatedValue(newVal, valObj, callBack){
	let obj = {}
	obj.value = newVal;
	obj.valid = valObj.valid;
	obj.errorMessage = valObj.errorMessage;
	obj.errorClassName = valObj.errorClassName;
	callBack(obj);
}

export function handleTitleChange(newVal, valObj, callBack){
	let obj = {}
	obj.errorMessage = valObj.errorMessage;
	obj.errorClassName = valObj.errorClassName;
	obj.valid = valObj.valid;
	obj.value = capitalizeFirstLetter(newVal);
	callBack(obj);
}
export function handleFirstNameChange(newVal, valObj, callBack){
	callBack(validateRequiredValue(newVal, valObj));
}
export function handleLastNameChange(newVal, valObj, callBack){
	callBack(validateRequiredValue(newVal, valObj));
}
export function handleUserNameChange(newVal, valObj, callBack){
  callBack(validateRequiredValue(newVal, valObj));
}
export function handleMiddleNameChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleSuffixChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleCountryChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleStateChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleCityChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleStr1Change(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleStr2Change(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handleZipCodeChange(newVal, valObj, callBack){
	let obj = {
		value: valObj.value,
		errorMessage: '',
		errorClassName: 'no-error',
		valid: true
	}
	if(newVal.trim().length < 1){
		obj.value = '';
		callBack(obj);
		return;
	}
	obj.value = newVal.trim();
	let errorMessages = '';
	let lastCharacter = newVal.slice(-1);
	if(!valueIsNumber(lastCharacter)){
		if(valueIsDash(lastCharacter)) {
			if(!valueHasNoMoreThanOneDash(newVal)){
				obj.valid = false;
				errorMessages += ' There are too many dashes in this value. ';
			}
		}else {
			obj.value = newVal.substr(0,newVal.length-1);
		}
	}
	if(obj.valid && !valueHasNoMoreThanOneDash(newVal)){
		obj.valid = false;
		errorMessages += ' There are too many dashes in this value. ';
	}
	let numberOfDigits = 10;
	if(valueIsCorrectLength(obj.value, numberOfDigits) > 0){
		obj.valid = false;
		errorMessages += ' There are more than '+numberOfDigits+' digits in this value.';
	} else if(valueIsCorrectLength(obj.value, 5) < 0){
		obj.valid = false;
		errorMessages += ' There are less than 5 digits in this value.';
	}
	if(!obj.valid){
		obj.errorClassName = 'text-input-error';
		obj.errorMessage = errorMessages;
	}
	callBack(obj);
}
export function handleEmailChange(newVal, valObj, callBack){
  let obj = {}
	obj.value = newVal.toLowerCase();
	obj.errorMessage = '';
	obj.errorClassName = 'no-error';
	obj.valid = true;

  const dmbs = JSON.parse(sessionStorage.getItem('DMBS'));
  if(dmbs && dmbs.user){
    if(newVal === dmbs.user?.email.toLowerCase()){
      callBack(obj);
      return;
    }
  }


	if(!emailIsInCorrectFormat(newVal.toLowerCase())){
		obj.errorMessage = 'This email is not formatted correctly.';
		obj.errorClassName='text-input-error';
		obj.valid = false;
		callBack(obj);
		return;
	}
	getIsEmailUniqueFromWebService(newVal.toLowerCase(), (unique)=>{
		if(unique==='false' || !unique){
			obj.errorMessage = 'This email is already in use. Please use another.';
			obj.errorClassName = 'text-input-error';
			obj.valid = false;
		}
		callBack(obj);
	});
}
export function handleCountryCodeChange(newVal, valObj, callBack){
	let obj = {
		value: valObj.value,
		errorMessage: '',
		errorClassName: 'no-error',
		valid: true
	}
	if(newVal.trim().length < 1){
		obj.value = '';
		callBack(obj);
		return;
	}
	obj.value = newVal.trim();
	let errorMessages = '';
	let lastCharacter = newVal.slice(-1);
	if(valueIsNumber(lastCharacter)){
		let numberOfDigits = 7;
		if(valueIsCorrectLength(newVal, numberOfDigits) > 0){
			obj.valid = false;
			errorMessages += ' There are more than '+numberOfDigits+' digits in this value.';
		}
	} else if(valueIsDash(lastCharacter)) {
		if(!valueHasNoMoreThanOneDash(newVal)){
			obj.valid = false;
			errorMessages += ' There are too many dashes in this value.';
		}
	} else {
		obj.value = newVal.substr(0,newVal.length-1);
	}
	if(obj.valid && !valueHasNoMoreThanOneDash(obj.value)){
		obj.valid = false;
		errorMessages += ' There are too many dashes in this value. ';
	}
	if(!obj.valid){
		obj.errorMessage = errorMessages;
		obj.errorClassName = 'text-input-error';
	}
	callBack(obj);
}
export function handleAreaCodeChange(newVal, valObj, callBack){
	let obj = {
		value: valObj.value,
		errorMessage: '',
		errorClassName: 'no-error',
		valid: true
	}
	if(newVal.length < 1){
		obj.value = '';
		callBack(obj);
		return;
	}
	obj.value = newVal.trim();
	if(valueIsInteger(newVal)){
		let numberOfDigits = 3;
		if(valueIsCorrectLength(newVal, numberOfDigits) !== 0){
			obj.valid = false;
			obj.errorMessage = 'This value must be either blank or '+numberOfDigits+' digits long.';
			obj.errorClassName = 'text-input-error';
		}
	} else {
		obj.valid = false;
		obj.errorMessage = 'This value can only have numbers';
		obj.errorClassName = 'text-input-error';
	}
	callBack(obj);
}
export function handleTelephonePrefixChange(newVal, valObj, callBack){
	let obj = {
		value: valObj.value,
		errorMessage: '',
		errorClassName: 'no-error',
		valid: true
	}
	if(newVal.length < 1){
		obj.value = '';
		callBack(obj);
		return;
	}
	obj.value = newVal;
	if(valueIsInteger(newVal)){
		let numberOfDigits = 3;
		if(valueIsCorrectLength(newVal, numberOfDigits) !== 0){
			obj.valid = false;
			obj.errorMessage = 'This value must be either blank or '+numberOfDigits+' digits long.';
			obj.errorClassName = 'text-input-error';
		}
	} else {
		obj.valid = false;
		obj.errorMessage = 'This value can only have numbers';
		obj.errorClassName = 'text-input-error';
	}
	callBack(obj);
}
export function handleLineNumberChange(newVal, valObj, callBack){
	let obj = {
		value: valObj.value,
		errorMessage: '',
		errorClassName: 'no-error',
		valid: true
	}
	if(newVal.length < 1){
		obj.value = '';
		callBack(obj);
		return;
	}
	obj.value = newVal;
	if(valueIsInteger(newVal)){
		let numberOfDigits = 4;
		if(valueIsCorrectLength(newVal, numberOfDigits) !== 0){
			obj.valid = false;
			obj.errorMessage = 'This value must be either blank or '+numberOfDigits+' digits long.';
			obj.errorClassName = 'text-input-error';
		}
	} else {
		obj.valid = false;
		obj.errorMessage = 'This value can only have numbers';
		obj.errorClassName = 'text-input-error';
	}
	callBack(obj);
}
export function handleBirthDateChange(newVal, valObj, callBack){
	setUnvalidatedValue(newVal, valObj, callBack);
}
export function handlePasswordChange(newVal, valObj, callBack){
	let errorMessages = '';
	let obj = {}
	obj.value = newVal.trim();
	obj.valid = true;
	obj.errorMessage = '';
	obj.errorClassName = 'no-error';
	let value = newVal.trim();
	if(valueHasSpaces(value)){
		obj.valid = false;
		errorMessages += '- No spaces';
	}
	if(valueIsCorrectLength(value, 8) < 0) {
		obj.valid = false;
		errorMessages += '- At least 8 characters ';
	}
	if(!valueHasSpecialCharacter(value)){
		obj.valid = false;
		errorMessages += '- At least 1 special character: ! @ # $ % ^ & * ( )  ';
	}
	if(!valueHasCapitalLetter(value)){
		obj.valid = false;
		errorMessages += '- At least 1 capital letter ';
	}
	if(!valueHasLowercaseLetter(value)){
		obj.valid = false;
		errorMessages += '- At least 1 lowercase letter ';
	}
	if(!valueHasNumeral(value)){
		obj.valid = false;
		errorMessages += '- At least 1 number ';
	}
	if(!obj.valid){
		obj.errorMessage = 'The Password MUST Have: ' + errorMessages;
		obj.errorClassName = 'text-input-error';
	}
	callBack(obj);
}

export function handleStudyChange(newVal, valObj, callBack){
	let obj = {
		value: newVal,
		errorMessage: '',
		errorClassName: 'no-error',
		valid: true
	}
	callBack(obj);
}

export function handleOrderNumChanged(newVal, valObj, callBack){
  callBack(validateInteger(newVal, valObj));
}

export function handleRuleTextChanged(newVal, valObj, callBack){
  callBack(validateRequiredValue(newVal, valObj));
}
