import React from 'react';
import { useNavigate } from 'react-router-dom';

const Body = ( { isLoggedIn, setIsLoggedIn } ) => {
  const navigate = useNavigate();
  const containerClassName = 'section-container-body';
  let dmbs = sessionStorage.getItem('DMBS')

  if(dmbs) {
    let dmbsObj = JSON.parse(dmbs);
    if(!dmbsObj.user){
      setIsLoggedIn(false);
    }
  } else {
    setIsLoggedIn(false);
  }

  if(!isLoggedIn){
    const actionTextClass = 'action-text';
    const inlineMessageClass='inline-message';
    const signUpClicked = () => {
      navigate('/profileForm');
    }
    const logInClicked = () => {
      navigate('/loginForm');
    }
    let signUpButton = <div className={actionTextClass} onClick={signUpClicked} >Sign Up</div>;
    let loginButton = <div className={actionTextClass} onClick={logInClicked} >Log In</div>;

    return(
      <div className={containerClassName}>
        <div>
          <h1>
      			Welcome to the community of Disciple Makers!
      		</h1>
      		<div>
            <p style={{textAlign: "center"}}>Disciple Maker Bible Study Community Project</p>
            <p style={{textAlign: "center"}}>Created by JMLatham LLC</p>
            <p style={{textAlign: "center"}}>Date 9/23/2023</p>
            <p style={{textAlign: "center"}}>
              <strong>Mission:</strong> To make disciples for Jesus through Bible study in an online community.
            </p>
            <p style={{textAlign: "center"}}>
              <strong>Vision:</strong> To provide Bible study opportunities for people all over the world for
              the purpose of helping them to be disciples of Christ Jesus and disciple makers.
            </p>
            <div className={inlineMessageClass} style={{textAlign: "center"}}>
              <div>{signUpButton}</div><div>&nbsp;or&nbsp;</div><div>{loginButton}</div><div>&nbsp;to join the community and start studying with us.</div>
            </div>
      		</div>
        </div>
      </div>
    )
  }

  return(
    <div className={containerClassName}>
      <div>
        <h1>
    			Welcome to the community, Disciple Maker!
    		</h1>
    		<div>
          <p style={{textAlign: "center"}}>Disciple Maker Bible Study Community Project</p>
          <p style={{textAlign: "center"}}>Created by JMLatham LLC</p>
          <p style={{textAlign: "center"}}>Date 9/23/2023</p>
          <p style={{textAlign: "center"}}>
            <strong>Mission:</strong> To make disciples for Jesus through Bible study in an online community.
          </p>
          <p style={{textAlign: "center"}}>
            <strong>Vision:</strong> To provide Bible study opportunities for people all over the world for
  		      the purpose of helping them to be disciples of Christ Jesus and disciple makers.
          </p>
    		</div>
      </div>
    </div>
  )
}

export default Body;
