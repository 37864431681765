import React, {useState} from 'react';
import ActionButton from '../formElements/ActionButton';
import Modal from '../pageElements/Modal';
import Likes from '../pageElements/Likes';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import ObjectDisplayer from '../pageElements/ObjectDisplayer';

const Conversation = ({ keyValue, conversation, token, permissions, userObj, refreshConversations }) => {
	const [ commentsArray, setCommentsArray ] = useState((Array.isArray(conversation?.comments))?conversation.comments:[]);
	const [ likesArray, setLikesArray ] = useState((Array.isArray(conversation?.likes))?conversation.likes:[]);
	const [ showDetailsModal, setShowDetailsModal ] = useState(false);
	const [ showCommentForm, setShowCommentForm ] = useState(false);
	const [ showComments, setShowComments ] = useState(false);
	const [ conversationClosed, setConversationClosed ] = useState(conversation?.closed);
	const conversationContainerClassName = (conversation?.deleted)?'display-none':'container-conversation';
	const getNewComment = (comment) => {
		let tempArray = [...commentsArray];
		sessionStorage.setItem('NewComment', JSON.stringify(comment));
		conversation.comments.push(comment);
		tempArray.push(comment);
		setCommentsArray(tempArray);
	};
	const showConversationUpdateForm = (conversationId) => {
		alert('show update conversation form.');
	};
	const deleteConversation = (conversationId) => {
		callWebServicePost({sessionId:token,conversationId:conversationId}, 'delete/conversation', (results) => {
			sessionStorage.setItem('deletionResults', JSON.stringify(results));
			refreshConversations();
		});
	};
	const closeConversation = (conversationId) => {
		callWebServicePost({sessionId:token,conversationId:conversationId}, 'close/conversation', (results) => {
			sessionStorage.setItem('closureResults', JSON.stringify(results));
			alert('Conversation closed to comments.');
		});
		setConversationClosed(true);
	};
	const likeTheConversation = (conversationId, user) => {
		callWebServicePost({sessionId:token, conversationId:conversationId, user:user}, 'toggle/conversation/like');
		let tempList = [...likesArray];
		let userIndex = tempList.indexOf(user);
		if(userIndex < 0){
			tempList.push(user);
		} else {
			tempList.splice(userIndex, 1);
		}
		setLikesArray(tempList);
	};
	const commentFormSection = <div className={(showCommentForm)?'display-flex':'display-none'}><CommentForm conversation={conversation} user={userObj} token={token} setShowCommentForm={setShowCommentForm} callBack={getNewComment}/></div>;
	const conversationTitleContainerClassName = 'container-conversation-title';
	const conversationTitleClassName = 'conversation-title';
	const conversationLikesButtonContainerClassName = '';
	const conversationLikesButtonClassName = 'conversation-likes-button';
	const conversationDetailsContainerClassName = 'container-conversation-details';
	const conversationContentContainerClassName = 'container-conversation-content';
	const conversationContentClassName = 'conversation-content';
	const conversationContentActionClassName = 'conversation-content-action';
	const conversationButtonDetailsClassName = 'button-action button-conversation-details-show';
	const conversationButtonDeleteClassName = 'button-action button-conversation-delete';
	const conversationButtonUpdateClassName = 'button-action button-conversation-update';
	const conversationButtonCloseClassName = 'button-action button-conversation-close';
	const conversationButtonCommentClassName = 'button-action buttonconversation-comment';
	const conversationButtonShowCommentsClassName = 'button-action button-conversation-comments-show';
	const conversationDateCreatedContainerClassName = 'container-conversation-date-created';
	const conversationClosedMessage = 'conversation-closed-message';
	const numberOfNonDeletedCommentsIsLessThanOne = (comments) => {
		let lessThanOne = true;
		if(!comments || !Array.isArray(comments)){
			return lessThanOne;
		}
		for(let index=0; index<comments.length; index++){
			if(!comments[index].deleted){
				lessThanOne = false;
				break;
			}
		}
		return lessThanOne;
	}

	let closedMessage = (conversationClosed)
		?<div  key={`cm_${keyValue}`} className={conversationClosedMessage}>CLOSED for Comments!</div>
		:<></>;
	function getLikeArray(likes){
		let likesArray = [];
		if(Array.isArray(likes)){
			likes.forEach((like)=>{
				likesArray.push(<div>{like}</div>);
			});
			return likesArray;
		}
		return <></>;
	}
	let children = [
		<div>{conversation.userName}</div>,
		<div>{conversation.dateCreated}</div>,
		<div style={{display:'flex',flexDirection:'column'}}>{getLikeArray(conversation.likes)}</div>,
		<div>{conversation.comments?.length}</div>
	];
	let titleSection = <div className={conversationTitleClassName}>{conversation.title.value}</div>;
	let textSection = <div className={conversationContentClassName}>{conversation.text.value}</div>;
	let conversationUpdateButton = (permissions.canUpdConversation && numberOfNonDeletedCommentsIsLessThanOne(conversation.comments) && !conversationClosed)
		?
			<div>
				<ActionButton display="Update" id={`updateBtn_${conversation._id}`} onClickFunction={()=>{
					showConversationUpdateForm(conversation._id);
				}} buttonClassName={conversationButtonUpdateClassName} containerClassName="container-col" />
			</div>
		:<></>;
	let conversationDeleteButton = (permissions.canDelConversation)
		?
			<div>
				<ActionButton display="Delete" id={`deleteBtn_${conversation._id}`} onClickFunction={()=>{
					let text = "Are you sure you want to delete this conversation? \n" + conversation.title.value;
					if (window.confirm(text) === true) {
						deleteConversation(conversation._id);
					}
				}} buttonClassName={conversationButtonDeleteClassName} containerClassName="container-col" />
			</div>
		:<></>;
	let conversationDetailsButton = (userObj?.userType === 'Administrator')?
		<div>
			<ActionButton display="Details" id={`detailsBtn_${conversation._id}`} onClickFunction={()=>{
				setShowDetailsModal(true)
			}} buttonClassName={conversationButtonDetailsClassName} containerClassName="container-col" />
		</div>:<></>;
	let conversationCloseButton = (permissions.canCloConversation && !conversationClosed)
		?
			<div>
				<ActionButton display="Close" id={`closeBtn_${conversation._id}`} onClickFunction={()=>{
					let text = "Are you sure you want to close this conversation? \n" + conversation.title.value;
					if (window.confirm(text) === true) {
						closeConversation(conversation._id);
					}
				}} buttonClassName={conversationButtonCloseClassName} containerClassName="container-col" />
			</div>
		:<></>;
	let commentButton = (!conversationClosed)
		?
			<div>
				<ActionButton
					display="Comment"
					id={`replyBtn_${conversation._id}`}
					onClickFunction={()=>{
						setShowCommentForm(true);
					}}
					buttonClassName={conversationButtonCommentClassName}
					containerClassName="container-col"
				/>
			</div>
		:<></>;
	let likesSection =
		<Likes
			likes={likesArray}
			likeId={conversation._id}
			user={userObj.email}
			toggleLike={
				()=>{
					likeTheConversation(conversation._id, userObj.email);
				}
			}
			containerClass={conversationLikesButtonContainerClassName}
			likeButtonClassName={conversationLikesButtonClassName}
		/>;
	let showHideCommentsButton =
		<ActionButton display={(showComments)?"Hide Comments":"Show Comments"} id={`showCommentsBtn_${conversation._id}`} onClickFunction={()=>{
			setShowComments(!showComments);
		}} buttonClassName={conversationButtonShowCommentsClassName} containerClassName="container-col" />;
	let commentListSection = (false)
		?<ObjectDisplayer objToDisplay={commentsArray} />
		:<CommentList conversation={conversation} setShowComments={setShowComments} />;
	let dateCreatedSection =
		<div className={conversationDateCreatedContainerClassName}>
			({conversation.dateCreated})
		</div>;
	let modalSection = <Modal show={showDetailsModal} setShow={setShowDetailsModal} children={children} modID={conversation._id} childrenContainerClassName={conversationDetailsContainerClassName} />;

	return(
		<div id={keyValue} className={conversationContainerClassName} key={keyValue} >
			<div className={conversationTitleContainerClassName}>
				{titleSection}
				{dateCreatedSection}
			</div>
			<div className={conversationContentContainerClassName}>
				{textSection}
				{closedMessage}
				{likesSection}
			</div>
			<div className={conversationContentActionClassName}>
				{conversationDetailsButton}
				{conversationUpdateButton}
				{conversationDeleteButton}
				{conversationCloseButton}
				{showHideCommentsButton}
				{commentButton}
				{commentFormSection}
			</div>
			<div className={(showComments)?'display-flex':'display-none'}>
				{commentListSection}
			</div>
			{modalSection}
		</div>
	);
};

export default Conversation;
