import React from 'react';
import Comment from './Comment';
import ActionButton from '../formElements/ActionButton';

const CommentList = ({ conversation, setShowComments }) => {
	const containerClassName = "container-comments-list";
	const hideButtonClassName = 'button-action button-comments-hide';
	let returnCode = <></>;
	let comments = [];

	conversation.comments.forEach((comment) => {
		if(!comment.deleted){
			comments.push(<Comment comment={comment} conversationId={conversation._id} conversationOpen={!conversation.closed} />);
		}
	});

	returnCode = <div className={containerClassName}>
		{comments}
		<ActionButton display="Hide Comments" id={`hideCommentsBtn_${conversation._id}`} onClickFunction={()=>{
			setShowComments(false);
		}} buttonClassName={hideButtonClassName} containerClassName="container-col" />
	</div>;

	return(returnCode);
};

export default CommentList;
