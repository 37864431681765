import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	logoutUser
} from '../../../lib/webserviceFunctions';
import ActionButton from '../../formElements/ActionButton';

const BibleStudyNavigationControl = ({isLoggedIn}) => {
	const navigate = useNavigate();
	const mainContainerClassName = 'container-control-bible-study-navigation';
	const actionButtonClassName = "navigation-button";
	const buttonContainerClassName = "navigation-button-container";
	const buttonDisplay = "Request a Study";
	const buttonId = "requestBtn";

	const handleClick = (path) => {
    navigate(path);
  }

	let returnCode = <></>;

	if(isLoggedIn){
		returnCode = <div className={mainContainerClassName}>
				<ActionButton display={buttonDisplay} id={buttonId} onClickFunction={()=>{ handleClick('/request') }} buttonClassName={actionButtonClassName} containerClassName={buttonContainerClassName} />
			</div>;
	}

	return(returnCode);
}

export default BibleStudyNavigationControl;
