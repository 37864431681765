import React, { useState, useEffect } from 'react';
import Rule from './Rule';
import ActionButton from '../formElements/ActionButton';
import TextInput from '../formElements/TextInput';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	handleOrderNumChanged,
	handleRuleTextChanged,
	validateInteger,
	validateRequiredValue
} from '../../lib/formElementHelperFunctions';
import './Community.css';

const getNewRuleObj = (value) => {
	let obj = {
		value: '',
		errorMessage: '',
		errorClassName: 'no-error',
		valid:true
	}
	return validateRequiredValue(value, obj);
}

const getNewOrderNumObj = (value) => {
	let obj = {
		value: '',
		errorMessage: '',
		errorClassName: 'no-error',
		valid:true
	}
	return validateInteger(value, obj);
}

const Rules = ( {show, setShow, canAddRule, canUpdRule, canDelRule} ) => {
	const [ children, setChildren ] = useState([]);
	const [ showAddForm, setShowAddForm ] = useState("display-none");
	const [ orderNumObj, setOrderNumObj ] = useState(getNewOrderNumObj(''));
	const [ ruleTextObj, setRuleTextObj ] = useState(getNewRuleObj(''));
	const [ retrieved, setRetrieved ] = useState(false);
	const sessionId = JSON.parse(sessionStorage.getItem("DMBS"))?.token;
	const showHideClassName = show ? "container-section-rules display-flex" : "container-section-rules display-none";
	const rulesSectionClassName = 'rules-section';
	const rulesSectionChildrenContainerClassName = "container-section-rules-children";
	const closeButtonContainerClassName = "container-button-rules-close";
	const orderNumLabelClassName = "form-label";
	const ruleLabelClassName = "rule-label-class";
	const createButtonClassName = "button-action button-rules-create";
	const submitButtonClassName = (orderNumObj.valid && ruleTextObj.valid)?"button-action button-rules-create-submit":"button-action button-rules-create-submit button-disabled";
	const cancelButtonClassName = "button-action button-rules-create-cancel";
	const closeButtonClassName = "button-action button-rules-close";


	useEffect(()=>{
		if(Array.isArray(children) && children.length>0){
			setRetrieved(true);
		}
	},[children]);

	useEffect(()=>{
		if(showAddForm === "display-none"){
			setRuleTextObj(getNewRuleObj(""));
			setOrderNumObj(getNewOrderNumObj(""));
		}
	},[showAddForm]);

	let addButton = <></>;

	if(canAddRule){
		addButton = <div>
			<ActionButton
				display="Add Rule"
				id="addRuleBtn"
				onClickFunction={()=>{
					setShowAddForm("");
				}}
				buttonClassName={createButtonClassName}
				containerClassName="container-col" />
		</div>;
	}

	const buildChildren = () => {
		callWebServicePost({}, 'read/rules', (rules) => {
			let retCode = [];
			if(Array.isArray(rules)){
				sessionStorage.setItem("communityRules", JSON.stringify(rules));
				rules.forEach((rule) => {
					retCode.push(<Rule rule={rule} canUpdRule={canUpdRule} canDelRule={canDelRule} sessionId={sessionId} setRetrieved={setRetrieved} buildChildren={buildChildren} />);
				});
			}

			if(canAddRule){
				retCode.push(
					<div name="rule-add-button-container" className="rule-add-button-container">
						{addButton}
					</div>
				);
			}

			if(Array.isArray(retCode)){
				setChildren(retCode);
			}
		});
	}

	let returnCode = <></>;

	if(show){
		if(!retrieved){
			buildChildren();
		}
		returnCode = <>
			<div name="rules-section-container" className={showHideClassName}>
				<section className={rulesSectionClassName}>
					<div name="rules-section-children-container" className={rulesSectionChildrenContainerClassName}>
						{children}
					</div>
					<div name="rule-add-form-container" className={showAddForm}>
						<div>
							<div className={orderNumLabelClassName}>
								Order Number:
							</div>
			        <TextInput
			          id="orderNumber"
			          type="text"
			          placeholder=""
			          inputClassName='soft-border shadow-bottom-right'
			          value={orderNumObj.value}
			          size='5'
			          errorMessage={orderNumObj.errorMessage}
			          errorClassName={orderNumObj.errorClassName}
			          containerClass="form-data"
			          onChangeFunct={
									(e) => {
										handleOrderNumChanged(e.target.value, orderNumObj, (obj) => {
											setOrderNumObj(obj);
										});
									}
								}
			        />
						</div>
						<div>
							<div className={ruleLabelClassName}>
								Rule:
							</div>
			        <TextInput
			          id="ruleText"
			          placeholder=""
			          inputClassName='soft-border shadow-bottom-right'
			          value={ruleTextObj.value}
			          size='25'
			          errorMessage={ruleTextObj.errorMessage}
			          errorClassName={ruleTextObj.errorClassName}
			          containerClass="form-data"
			          onChangeFunct={
									(e) => {
										handleRuleTextChanged(e.target.value, orderNumObj, (obj) => {
											setRuleTextObj(obj);
										});
									}
								}
			        />
						</div>
						<div>
							<ActionButton
								display="Submit"
								id="submitRuleBtnAdd"
								onClickFunction={()=>{
									if(orderNumObj.valid && ruleTextObj.valid){
										callWebServicePost({sessionId:sessionId,orderNum:orderNumObj.value,ruleText:ruleTextObj.value}, 'create/rule', (results) => {
											sessionStorage.setItem('createResults', JSON.stringify(results));
											buildChildren();
										});
										setShowAddForm("display-none");
									} else {
										alert('The form is not valid. Please fix the errors before submitting the form.');
									}
								 }}
								buttonClassName={submitButtonClassName}
								containerClassName="container-col" />
							<ActionButton
								display="Cancel"
								id="cancelRuleBtnAdd"
								onClickFunction={()=>{
									setShowAddForm("display-none");
								 }}
								buttonClassName={cancelButtonClassName}
								containerClassName="container-col" />
						</div>
					</div>
					<div name="rules-close-button-container" className={closeButtonContainerClassName}>
						<ActionButton
							display="Close"
							id="modalCloseBtn_commRules"
							onClickFunction={()=>{
								sessionStorage.removeItem("communityRules");
								setShow(false);
							}}
							buttonClassName={closeButtonClassName}
							containerClassName="container-col" />
					</div>
				</section>
			</div>
			</>;
	}

	return(returnCode);
}

export default Rules;
