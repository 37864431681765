import React, { useState } from 'react';
import ActionButton from '../formElements/ActionButton';
import Likes from '../pageElements/Likes';
import CommentForm from './CommentForm';
import ReplyList from './ReplyList';
import ReplyForm from './ReplyForm';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';

const Comment = (props) => {
	const { comment, conversationId, conversationOpen } = props;
	const [ showForm, setShowForm ] = useState(false);
	const [ showReplyForm, setShowReplyForm ] = useState(false);
	const [ showReplies, setShowReplies ] = useState(false);
	const [ deleted, setDeleted ] = useState(comment.deleted);
	const [ likesArray, setLikesArray ] = useState(comment.likes);
	const [ repliesArray, setRepliesArray ] = useState(comment.replies);
	const sessionId = JSON.parse(sessionStorage.getItem('DMBS')).token;
	const userObj = JSON.parse(sessionStorage.getItem('DMBS')).user;
	const numberOfNonDeletedRepliesIsLessThanOne = (replies) => {
		let lessThanOne = true;
		if(!replies || !Array.isArray(replies)){
			return lessThanOne;
		}
		for(let index=0; index<replies.length; index++){
			if(!replies[index].deleted){
				lessThanOne = false;
				break;
			}
		}
		return lessThanOne;
	};
	const getNewReply = (reply) => {
		let tempArray = [...repliesArray];
		sessionStorage.setItem('NewReply', JSON.stringify(reply));
		comment.replies.push(reply);
		tempArray.push(reply);
		setRepliesArray(tempArray);
	};
	const canDelete = (comment?.replies && numberOfNonDeletedRepliesIsLessThanOne(comment.replies));
	const canUpdate = (comment?.replies && numberOfNonDeletedRepliesIsLessThanOne(comment.replies));
	const commentForm = <CommentForm conversId={conversationId} comment={comment} user={userObj} token={sessionId} setShowCommentForm={setShowForm}/>;
	const replyForm = <ReplyForm conversId={conversationId} comment={comment} user={userObj} token={sessionId} setShowReplyForm={setShowReplyForm} callBack={getNewReply}/>;
	const likeTheComment = (commentId) => {
		callWebServicePost({sessionId:sessionId, conversationId:conversationId, commentId:commentId, user:userObj.email}, 'toggle/comment/like');
		let tempArray = [...likesArray];
		let index = likesArray.indexOf(userObj.email);
		if(index >= 0){
			tempArray.splice(index, 1);
		} else {
			tempArray.push(userObj.email);
		}
		setLikesArray(tempArray);
	};
	const commentTextClassName = 'comment-text';
	const commentUsernameClassName = 'comment-username';
	const commentDatecreatedClassName = 'comment-datecreated';
	const replyContentAction = 'reply-content-action';
	const containerClassName = 'container-comment';
	const replyCancelButtonClassName = 'button-action button-comment-reply-cancel';
	const replyButtonClassName = 'button-action button-comment-reply';
	const updateButtonClassName = 'button-action button-comment-update';
	const deleteButtonClassName = 'button-action button-comment-delete';
	const showRepliesButtonClassName = 'button-action button-comment-replies-show';
	const commentLikesContainerClassName = '';
	const commentLikesButtonClassName = 'comment-likes-button';
	const commentInfoClassName = 'comment-info';
	const commentActionClassName = 'comment-action'

	let replyButton = (conversationOpen && showReplyForm)?
		<div>
			<ActionButton
				display="Cancel"
				id={`replyCancelBtn_${comment._id}`}
				onClickFunction={()=>{
					setShowReplyForm(false);
				}}
				buttonClassName={replyCancelButtonClassName}
				containerClassName="container-col"
			/>
		</div>
	:(conversationOpen)?
		<div>
			<ActionButton
				display="Reply"
				id={`replyBtn_${comment._id}`}
				onClickFunction={()=>{
					setShowReplyForm(true);
				}}
				buttonClassName={replyButtonClassName}
				containerClassName="container-col"
			/>
		</div>
	:<></>;

	let userNameSection = (comment?.userName?.userName)
	?
		<>
			<div>
				{comment?.userName?.userName}
			</div>
			<div>
				{comment?.userName?.email}
			</div>
		</>
	:
		<div>
			{comment?.userName}
		</div>;


	return(
		<div className={(!deleted)?containerClassName:'display-none'}>
			<div className={commentTextClassName}>
				{comment.text}
			</div>
			<div className={commentInfoClassName}>
				<div className={commentUsernameClassName}>
					{userNameSection}
				</div>
				<div className={commentDatecreatedClassName}>
					{comment.dateCreated}
				</div>
			</div>
			<div className={commentActionClassName}>
				<ActionButton display={(showForm)?"Cancel":"Update"} id={`updateBtn_${comment._id}`} onClickFunction={()=>{
					setShowForm(!showForm);
				}} buttonClassName={updateButtonClassName} containerClassName={`container-col ${(!canUpdate)?'display-none':''}`} />
				<div className={(showForm)?'display-flex':'display-none'}>{commentForm}</div>
				<ActionButton
					display="Delete" id={`deleteBtn_${comment._id}`}
					onClickFunction={
						()=>{
							let text = "Are you sure you want to delete the following comment? \n" + comment.text;
						  if (window.confirm(text) === true) {
								callWebServicePost({sessionId:sessionId, conversationId:conversationId, comment:comment}, 'delete/comment', (commPermissions) => {
									if(commPermissions){
										sessionStorage.setItem('commentDelete',JSON.stringify(commPermissions));
										setDeleted(true);
									}
								});
						  }
						}
					}
					buttonClassName={deleteButtonClassName} containerClassName={`container-col ${(!showForm && canDelete)?'':'display-none'}`} />
					<Likes
						likes={likesArray}
						likeId={comment._id}
						toggleLike={
							()=>{
								likeTheComment(comment._id);
							}
						}
						containerClass={commentLikesContainerClassName}
						likeButtonClassName={commentLikesButtonClassName}
					/>
			</div>
			<div className={replyContentAction}>
				<div>
					<ActionButton display={(showReplies)?"Hide Replies":"Show Replies"} id={`showRepliesBtn_${comment._id}`} onClickFunction={()=>{
						setShowReplies(!showReplies);
					}} buttonClassName={showRepliesButtonClassName} containerClassName="container-col" />
				</div>
				{replyButton}
				<div className={(showReplyForm)?'display-flex':'display-none'}>{replyForm}</div>
			</div>
			<div className={(showReplies)?'display-flex':'display-none'}>
				<ReplyList conversationId={conversationId} comment={comment} replies={repliesArray} setShowReplies={setShowReplies} />
			</div>
		</div>
	);
};

export default Comment;
