import React from 'react';
import ActionButton from '../formElements/ActionButton';
import TextInput from '../formElements/TextInput';
import {
	validateRequiredValue
} from '../../lib/formElementHelperFunctions';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';

const ConversationForm = ({ token, selectedCommunity, getConversations, conversationInfo, setConversationInfo, setShowAddConversation }) => {
	const submitConversationButtonClassName = "button-action button-conversation-submit";
	const cancelConversationButtonClassName = "button-action button-conversation-cancel";
	const conversationFormContainerClassName = 'container-conversation-form';
	const handleTitleChange = (val, obj, callBack) => {
		callBack(validateRequiredValue(val, obj));
	};

	const handleContentChange = (val, obj, callBack) => {
		callBack(validateRequiredValue(val, obj));
	}
	const submitAddConversationForm = () => {
		let parameters = {
			sessionId:token,
			conversation:{
				communityId: selectedCommunity,
				title: conversationInfo.title,
				text: conversationInfo.text,
				comments: [],
				likes: [],
				deleted: false,
				closed: false
			}
		};
		if(conversationInfo.title.valid && conversationInfo.text.valid){
			callWebServicePost(parameters, 'create/conversation', (results) => {
				sessionStorage.setItem('creationResults', JSON.stringify(results));
				setShowAddConversation(false);
				getConversations(selectedCommunity);
			});
		} else if(!conversationInfo.title.valid){
			handleTitleChange('', conversationInfo.title, (obj) => {
				setConversationInfo({title:obj,text:conversationInfo.text});
			});
		} else if(!conversationInfo.text.valid){
			handleContentChange('', conversationInfo.title, (obj) => {
				setConversationInfo({title:conversationInfo.title,text:obj});
			})
		}
	}

	let returnCode = <div className={conversationFormContainerClassName}>
		<div className='container-row'>
			<div>Title: </div>
			<div>
				<TextInput
					id="title"
					type="text"
					placeholder=''
					inputClassName='soft-border shadow-bottom-right'
					value={conversationInfo.title.value}
					size='25'
					errorMessage={conversationInfo.title.errorMessage}
					errorClassName={conversationInfo.title.errorClassName}
					containerClass="form-data"
					onChangeFunct={(e) => handleTitleChange(e.target.value, conversationInfo.title, (obj) => {
						setConversationInfo({title:obj,text:conversationInfo.text});
					})}
				/>
			</div>
		</div>
		<div className='container-row'>
			<div>Content: </div>
			<div>
				<TextInput
					id="content"
					type="text"
					placeholder=''
					inputClassName='soft-border shadow-bottom-right'
					value={conversationInfo.text.value}
					size='25'
					errorMessage={conversationInfo.text.errorMessage}
					errorClassName={conversationInfo.text.errorClassName}
					containerClass="form-data"
					onChangeFunct={(e) => handleContentChange(e.target.value, conversationInfo.text, (obj) => {
						setConversationInfo({title:conversationInfo.title,text:obj});
					})}
				/>
			</div>
		</div>
		<ActionButton display="Submit" id="addConversationSubmitBtn" onClickFunction={()=>{
			submitAddConversationForm();
		}} buttonClassName={submitConversationButtonClassName} containerClassName="container-col" />
		<ActionButton display="Cancel" id="addConversationCloseBtn" onClickFunction={()=>{
			setShowAddConversation(false);
		}} buttonClassName={cancelConversationButtonClassName} containerClassName="container-col" />
	</div>;

	return(returnCode);
};

export default ConversationForm;
