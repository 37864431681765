import React from 'react';
import TextInput from '../formElements/TextInput';
import {
	handleUserNameChange
} from '../../lib/formElementHelperFunctions';

export default function UserName({
  userNameObj, setUserNameObj
}) {
  const mainContainerClassName = 'container-user-name';
  const userNameItemContainerClassName = 'form-item-container';
  const userNameLabelClassName = 'form-title';
  const asteriskClassName = 'required-item';

  return(
    <div className={mainContainerClassName}>
      <div className={userNameItemContainerClassName}>
  			<div className={userNameLabelClassName}>
  				<label htmlFor="userName"> <span className={asteriskClassName} >*</span> UserName: </label>
  			</div>
  			<TextInput
  				id="userName"
  				type="text"
  				placeholder="user name"
  				inputClassName='soft-border shadow-bottom-right'
  				value={userNameObj.value}
  				size='25'
  				errorMessage={userNameObj.errorMessage}
  				errorClassName={userNameObj.errorClassName}
  				containerClass="form-data"
  				onChangeFunct={(e) => handleUserNameChange(e.target.value, userNameObj, (obj)=>setUserNameObj(obj))}
  				autoComplete="off"
  			/>
  		</div>
    </div>
  );
}
