import React, { useState, useEffect } from 'react';
import ActionButton from '../formElements/ActionButton';
import SelectInput from '../formElements/SelectInput';
import './recordPaginationControl.css';

const getSelectArrayValues = (maxValue) => {
	let selectArray = [];
	for(let index=1; index<=maxValue; index++){
		selectArray.push({name:index,value:index});
	}
	return selectArray;
}

const RecordPaginationControl = ({ currentPage, setCurrentPage, limitValue, maxValue, containerClass }) => {
	const [ pgCurr, setPgCurr ] = useState(currentPage);
	const containerClassName = `pagination-control ${containerClass}`;
	const lastPage = Math.ceil(maxValue/limitValue);
	const onLastPage = (currentPage >= lastPage);
	const onFirstPage = (currentPage <= 1);
	const firstButtonClassName = (onFirstPage)?"button-action pagination-button-disabled":"button-action pagination-button";
	const previousButtonClassName = (onFirstPage)?"button-action pagination-button-disabled":"button-action pagination-button";
	const nextButtonClassName = (onLastPage)?"button-action pagination-button-disabled":"button-action pagination-button";
	const lastButtonClassName = (onLastPage)?"button-action pagination-button-disabled":"button-action pagination-button";
	const paginationControlFirstContainerClassName = 'pagination-control-first-container';
	const paginationControlPreviousContainerClassName = 'pagination-control-prev-container';
	const paginationControlGotoContainerClassName = 'pagination-control-goto-container';
	const paginationControlNextContainerClassName = 'pagination-control-next-container';
	const paginationControlLastContainerClassName = 'pagination-control-last-container';
	const gotoSelectClassName="pagination-control-goto-select";
	const gotoOptionClassName="pagination-control-goto-option";
	const gotoContainerClassName="pagination-control-goto-container";
	const selectArray = getSelectArrayValues(lastPage);
	let controlCode = <>
		<div className={paginationControlFirstContainerClassName}>
			<ActionButton
				display="<<"
				id={`firstBtn`}
				onClickFunction={
					()=>{
						if(!onFirstPage){
							setCurrentPage(1);
							setPgCurr(1);
						}
					}
				}
				buttonClassName={firstButtonClassName}
				containerClassName="container-col"
			/>
		</div>
		<div className={paginationControlPreviousContainerClassName}>
			<ActionButton
				display="<"
				id={`prevBtn`}
				onClickFunction={
					()=>{
						if(!onFirstPage){
							setCurrentPage(currentPage - 1);
							setPgCurr(currentPage - 1);
						}
					}
				}
				buttonClassName={previousButtonClassName}
				containerClassName="container-col"
			/>
		</div>
		<div className={paginationControlGotoContainerClassName}>
			<label>
				Go to:
				<SelectInput
					values={selectArray}
					selectedValue={currentPage}
					id="gotoSelector"
					name="gotoSelector"
					size="1"
					blank={false}
					blankValue={-1}
					blankDisplay=""
					multiple={false}
					onChangeFunct={(e)=>{
						setCurrentPage(parseInt(e.target.value));
					}}
					selectClassName={gotoSelectClassName}
					optionClassName={gotoOptionClassName}
					containerClass={gotoContainerClassName}
				/>
			</label>
		</div>
		<div className={paginationControlNextContainerClassName}>
			<ActionButton
				display=">"
				id={`nextBtn`}
				onClickFunction={
					()=>{
						if(!onLastPage){
							setCurrentPage(currentPage + 1);
							setPgCurr(currentPage + 1);
						}
					}
				}
				buttonClassName={nextButtonClassName}
				containerClassName="container-col"
			/>
		</div>
		<div className={paginationControlLastContainerClassName}>
			<ActionButton
				display=">>"
				id={`lastBtn`}
				onClickFunction={
					()=>{
						if(!onLastPage){
							setCurrentPage(lastPage);
							setPgCurr(lastPage);
						}
					}
				}
				buttonClassName={lastButtonClassName}
				containerClassName="container-col"
			/>
		</div>
	</>;
	let returnCode = <div className={containerClassName}>{controlCode}</div>;
	return(returnCode);
}

export default RecordPaginationControl;
