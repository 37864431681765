import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';
import {
	logoutUser,
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	clearStorage,
	createRandomString
} from '../../lib/helperFunctions';
import PasswordInfo from './PasswordInfo';
import UserName from './UserName';
import Email from './Email';
import Name from './Name';
import AddressInfo from './AddressInfo';
import Birthdate from './Birthdate';
import PhoneNumber from './PhoneNumber';
import ActionButton from '../formElements/ActionButton';
import StudySelection from './StudySelection';
import EmailConfirmationCodeForm from './EmailConfirmationCodeForm';
import {
	getCreatedDateTime
} from '../../lib/formElementHelperFunctions';

const Profile = ({setIsLoggedIn, stepValue}) => {
	const navigate = useNavigate();
	const dmbs = JSON.parse(sessionStorage.getItem("DMBS"));
	const user = dmbs?.user;
	const userTempSession = JSON.parse(sessionStorage.getItem("tempUser"));
	const userTempLocal = JSON.parse(localStorage.getItem("tempUser"));
	const getStarterObject = (value) => {
		return {
			value: value,
			errorMessage: '',
			errorClassName: 'no-error',
			valid:true
		}
	}
	const getStarterValue = (key) => {
		let key1 = "";
		let key2 = "";
		let isSub = key.indexOf('.') > 0;
		if(isSub){
			let ind = key.indexOf('.');
			key1 = key.slice(0,ind);
			key2 = key.slice(ind+1);
		}
		let value = "";
		if(user){
			if(isSub){
				value = user[key1][key2];
			} else{
				value = user[key]
			};
		} else if(userTempLocal){
			if(isSub){
				value = userTempLocal[key1][key2];
			} else{
				value = userTempLocal[key];
			};
		} else if(userTempSession){
			if(isSub){
				value = userTempSession[key1][key2];
			} else{
				value = userTempSession[key];
			};
		} else if(key === 'address.state'){
			return "TX";
		} else if(key === 'address.country'){
			return "USA";
		}
		return value;
	}
	const [ str1Obj,            setStr1Obj ]            = useState(getStarterObject(getStarterValue('address.street_1')));
  const [ str2Obj,            setStr2Obj ]            = useState(getStarterObject(getStarterValue('address.street_2')));
  const [ cityObj,            setCityObj ]            = useState(getStarterObject(getStarterValue('address.city')));
  const [ stateObj,           setStateObj ]           = useState(getStarterObject(getStarterValue('address.state')));
  const [ countryObj,         setCountryObj ]         = useState(getStarterObject(getStarterValue('address.country')));
  const [ zipcodeObj,         setZipcodeObj ]         = useState(getStarterObject(getStarterValue('address.zipCode')));
  const [ titleObj,           setTitleObj ]           = useState(getStarterObject(getStarterValue('name.title')));
  const [ firstNameObj,       setFirstNameObj ]       = useState(getStarterObject(getStarterValue('name.firstName')));
  const [ middleNameObj,      setMiddleNameObj ]      = useState(getStarterObject(getStarterValue('name.middleName')));
  const [ lastNameObj,        setLastNameObj ]        = useState(getStarterObject(getStarterValue('name.lastName')));
  const [ suffixObj,          setSuffixObj ]          = useState(getStarterObject(getStarterValue('name.suffix')));
	const [ userNameObj,				setUserNameObj ]				= useState(getStarterObject(getStarterValue('name.userName')));
  const [ emailObj,           setEmailObj ]           = useState(getStarterObject(getStarterValue('email')));
	const [ emailConfCode, 			setEmailConfCode ]			= useState('');
  const [ countryCodeObj,     setCountryCodeObj ]     = useState(getStarterObject(getStarterValue('phone.countryCode')));
  const [ areaCodeObj,        setAreaCodeObj ]        = useState(getStarterObject(getStarterValue('phone.areaCode')));
  const [ telephonePrefixObj, setTelephonePrefixObj ] = useState(getStarterObject(getStarterValue('phone.telephonePrefix')));
  const [ lineNumberObj,      setLineNumberObj ]      = useState(getStarterObject(getStarterValue('phone.lineNumber')));
  const [ birthdateObj,       setBirthdateObj ]       = useState(getStarterObject(getStarterValue('bDate')));
  const [ passwordObj,        setPasswordObj ]        = useState(getStarterObject(getStarterValue('password')));
	const [ studySelectObj, 		setStudySelectObj ]			= useState(getStarterObject(1));
	const [ stepObj, 						setStepObj ]						= useState(stepValue);
	const [ profileSubDisabled, setProfileSubDisabled ] = useState("");
	const [ personalNxtDisabled, setPersonalNxtDisabled ] = useState("button-disabled");
	const [ addressNxtDisabled, setAddressNxtDisabled ] = useState("");
	const [ emailConfNxtDisabled, setEmailConfNxtDisabled ] = useState("button-disabled");
	const [ stripeNxtDisabled,  setStripeNxtDisabled ]  = useState("");
	const mainContainerClassName = 'container-profile-main';
	const actionButtonsContainerClassName = 'container-profile-action-buttons';
	const actionButtonClassName = "button-action button-action-profile";
	const checkFormValidity = () => {
		switch(stepObj){
			case "profileInfo": // profile
				let validFNpi = false;
				let validLNpi = false;
				let validEMpi = false;
				let validUNpi = false;
				let validELpi = false;
				let validCountrypi = false;
				let validStatepi = false;
				let validCitypi = false;
				let validStreet_1pi = false;
				let validStreet_2pi = false;
				let validZipCodepi = false;
				let validBirthdaypi = false;
				validFNpi = (firstNameObj.value.trim() !== "" && firstNameObj.valid);
				validLNpi = (lastNameObj.value.trim() !== "" && lastNameObj.valid);
				validUNpi = (userNameObj.value.trim() !== "" && userNameObj.valid);
				validELpi = (titleObj.valid && suffixObj.valid && middleNameObj.valid && countryCodeObj.valid && areaCodeObj.valid && telephonePrefixObj.valid && lineNumberObj.valid);
				validCountrypi = countryObj.valid;
				validStatepi = stateObj.valid;
				validCitypi = cityObj.valid;
				validStreet_1pi = str1Obj.valid;
				validStreet_2pi = str2Obj.valid;
				validZipCodepi = zipcodeObj.valid;
				validBirthdaypi = birthdateObj.valid;
				let personalValidpi = validFNpi && validLNpi && validUNpi && validELpi;
				let addressValidpi = validCountrypi && validStatepi && validCitypi && validStreet_1pi && validStreet_2pi && validZipCodepi && validBirthdaypi;
				setProfileSubDisabled((personalValidpi && addressValidpi)?"":"button-disabled");
				break;
			case "addressInfo": // address
				let validCountry = false;
				let validState = false;
				let validCity = false;
				let validStreet_1 = false;
				let validStreet_2 = false;
				let validZipCode = false;
				let validBirthday = false;
				validCountry = countryObj.valid;
				validState = stateObj.valid;
				validCity = cityObj.valid;
				validStreet_1 = str1Obj.valid;
				validStreet_2 = str2Obj.valid;
				validZipCode = zipcodeObj.valid;
				validBirthday = birthdateObj.valid;
				let addressValid = validCountry && validState && validCity && validStreet_1 && validStreet_2 && validZipCode && validBirthday
				setAddressNxtDisabled((addressValid)?"":"button-disabled");
				break;
			case "stripeCode": // stripe
				setStripeNxtDisabled(true);
				break;
			default: // personal
				let validFN = false;
				let validLN = false;
				let validEM = false;
				let validUN = false;
				let validPW = false;
				let validEL = false;
				validFN = (firstNameObj.value.trim() !== "" && firstNameObj.valid);
				validLN = (lastNameObj.value.trim() !== "" && lastNameObj.valid);
				validEM = (emailObj.value.trim() !== "" && emailObj.valid);
				if(validEM){
					setEmailConfCode(createRandomString(9));
				}
				validUN = (userNameObj.value.trim() !== "" && userNameObj.valid);
				validPW = (passwordObj.value.trim() !== "" && passwordObj.valid);
				validEL = (titleObj.valid && suffixObj.valid && middleNameObj.valid && countryCodeObj.valid && areaCodeObj.valid && telephonePrefixObj.valid && lineNumberObj.valid);
				let personalValid = validFN && validLN && validEM && validUN && validPW && validEL;
				setPersonalNxtDisabled((personalValid)?"":"button-disabled");
		}
	};
	useEffect(()=>{
		checkFormValidity();
	},[
		zipcodeObj,
		firstNameObj,
		lastNameObj,
		userNameObj,
		emailObj,
		areaCodeObj,
		telephonePrefixObj,
		lineNumberObj,
		passwordObj
	]);
	useEffect(()=>{
		addUserLevelToUserInfo();
	},[stepObj]);

	const getUserInfo = (type) => {
		let userObj = user;
		let userInfo = {}
		if(userObj){
			userInfo = userObj;
		}
		if(!userInfo?.name) userInfo.name = {};
		if(titleObj?.value) userInfo.name.title = titleObj.value;
		if(firstNameObj?.value) userInfo.name.firstName = firstNameObj.value;
		if(middleNameObj?.value) userInfo.name.middleName = middleNameObj.value;
		if(lastNameObj?.value) userInfo.name.lastName = lastNameObj.value;
		if(suffixObj?.value) userInfo.name.suffix = suffixObj.value;
		if(userNameObj?.value) userInfo.name.userName = userNameObj.value;
		if(birthdateObj?.value) userInfo.bDate = birthdateObj.value;
		if(!userInfo?.address) userInfo.address = {};
		if(str1Obj?.value) userInfo.address.street_1 = str1Obj.value;
		if(str2Obj?.value) userInfo.address.street_2 = str2Obj.value;
		if(cityObj?.value) userInfo.address.city = cityObj.value;
		if(stateObj?.value) userInfo.address.state = stateObj.value;
		if(zipcodeObj?.value) userInfo.address.zipCode = zipcodeObj.value;
		if(countryObj?.value) userInfo.address.country = countryObj.value;
		if(emailObj?.value) userInfo.email = emailObj.value;
		if(!userInfo.phone) userInfo.phone = {};
		if(countryCodeObj?.value) userInfo.phone.countryCode = countryCodeObj.value;
		if(areaCodeObj?.value) userInfo.phone.areaCode = areaCodeObj.value;
		if(telephonePrefixObj?.value) userInfo.phone.telephonePrefix = telephonePrefixObj.value;
		if(lineNumberObj?.value) userInfo.phone.lineNumber = lineNumberObj.value;
		userInfo.createDate = userObj?.createDate||getCreatedDateTime();
		if(!userObj?.userType) userInfo.userType = 'User';

		switch(type){
			case "session":
			case "local":
				userInfo["password"]=passwordObj.value;
				break;
			case "tempUser":
				try{
					userInfo = JSON.parse(sessionStorage.getItem('tempUser'));
				} catch(e) {}
				break;
			default:
				// don't change anything
		}
		return userInfo;
	}

	const addUserLevelToUserInfo = () => {
		if(stepObj === "studyBump"){
			try{
				let localLevels = JSON.parse(sessionStorage.getItem('levels'));
				let localTempUser = JSON.parse(sessionStorage.getItem('tempUser'));
				localLevels.every((level, lvlIndex, arr)=>{
					if(parseInt(studySelectObj.value) === parseInt(level.value)){
						localTempUser['userLevel'] = {
							name:level.name,
							value:parseInt(level.value)
						}
						sessionStorage.setItem('tempUser', JSON.stringify(localTempUser));
						return false;
					}
					return true;
				});
			} catch(e) {}
			handleStripeCodeClicked();
		}
	}

	const getEmailObject = () => {
		try{
			if(emailObj.value && emailObj.value.trim() !== ""){
				if(firstNameObj.value && lastNameObj.value && firstNameObj.value.trim() !== "" && lastNameObj.value.trim() !== ""){
					return {
						emailObject:{
							email: emailObj.value,
							page: {
								name: `${firstNameObj.value} ${lastNameObj.value}`,
								page: 'DMBS Profile Create New User',
								createDate: getCreatedDateTime()
							}
						}
					}
				}
			}
			return null;
		} catch(e) {
			return null;
		}
	}
	const captureEmail = async (emlObj) => {
		if(emlObj){
			const results = await callWebServicePost(emlObj, 'captureEmail');
			sessionStorage.setItem('emlRes', JSON.stringify(results));
		}
	}

	const sendConfirmationEmailCode = () => {
		let confirmObj = {eml:emailObj.value, emlcc:emailConfCode, name:firstNameObj.value};
		callWebServicePost(confirmObj, 'emailConf');
	}

	const handlePersonalInfoClicked = async () => {
		let tempUserString = localStorage.getItem('tempUser');
		if(!tempUserString){
			captureEmail(getEmailObject());
		} else {
			let tempUserObj = JSON.parse(tempUserString);
			if(!tempUserObj.email){
				captureEmail(getEmailObject());
			}
		}
		sendConfirmationEmailCode();
		sessionStorage.setItem('tempUser',JSON.stringify(getUserInfo("session")));
		localStorage.setItem('tempUser', JSON.stringify(getUserInfo("local")));
		setStepObj('addressInfo');
	}

	const handleAddressInfoClicked = async () => {
		sessionStorage.setItem('tempUser',JSON.stringify(getUserInfo("session")));
		localStorage.setItem('tempUser', JSON.stringify(getUserInfo("local")));
		setStepObj('emailConfirmation');
	}

	const handleEmailConfClicked = async () => {
		setStepObj('studySelect');
	}

	const handleStudySelectClicked = async () => {
		setStepObj('studyBump');
	}

	const handleStudyBumpClicked = async () => {
		setStepObj('stripeCode');
	}

	const handleStripeCodeClicked = async () => {
		const dataSource = getUserInfo("tempUser");
		const results = await callWebServicePost(dataSource, 'create/user');
		sessionStorage.setItem('DMBS', JSON.stringify(results));
		sessionStorage.removeItem('emlRes');
		sessionStorage.removeItem('levels');
		sessionStorage.removeItem('tempUser');
		localStorage.removeItem('tempUser');
		navigate('/');
		setIsLoggedIn(true);
	}

	const handleProfileInfoSubmitClicked = async () => {
		const dataSource = getUserInfo();
		const dmbs = JSON.parse(sessionStorage.getItem('DMBS'));
		dataSource.sessionId = dmbs.token;
		const results = await callWebServicePost(dataSource, 'update/user');
		Object.keys(results).map(key=>{dmbs[key] = results[key]});
		sessionStorage.setItem('DMBS', JSON.stringify(dmbs));
		sessionStorage.removeItem('tempUser');
		localStorage.removeItem('tempUser');
		if(results.message.action === "LOGOUT"){
			logoutUser(dmbs.token);
			clearStorage();
		}
		navigate('/');
	}

	const handleCancelClicked = () => {
		navigate('/');
	}

	const getActionButtons = () => {
		let actionButtons = <></>;

		switch(stepObj){
			case 'profileInfo':
				if(profileSubDisabled === ""){
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="Cancel" id="profileCancelBtn" onClickFunction={()=>{ handleCancelClicked() }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Submit" id="profileSubBtn" onClickFunction={()=>{ handleProfileInfoSubmitClicked()}} buttonClassName={actionButtonClassName + " " + profileSubDisabled} containerClassName="container-col" />
					</div>;
				} else {
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="Cancel" id="profileCancelBtn" onClickFunction={()=>{ handleCancelClicked() }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Submit" id="profileSubBtn" buttonClassName={actionButtonClassName + " " + profileSubDisabled} containerClassName="container-col" />
					</div>;
				}
				break;
			case 'personalInfo':
				if(personalNxtDisabled === ""){
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="Cancel" id="profileCancelBtn" onClickFunction={()=>{ handleCancelClicked() }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Next >>" id="personalInfoNxtBtn" onClickFunction={()=>{ handlePersonalInfoClicked()}} buttonClassName={actionButtonClassName + " " + personalNxtDisabled} containerClassName="container-row" />
					</div>;
				} else {
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="Cancel" id="profileCancelBtn" onClickFunction={()=>{ handleCancelClicked() }} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Next >>" id="personalInfoNxtBtn" buttonClassName={actionButtonClassName + " " + personalNxtDisabled} containerClassName="container-row" />
					</div>;
				}
				break;
			case 'addressInfo':
				if(addressNxtDisabled === ""){
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="<< Prev" id="addressInfoPrevBtn" onClickFunction={()=>{ setStepObj('personalInfo')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Next >>" id="addressInfoNxtBtn" onClickFunction={()=>{ handleAddressInfoClicked()}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
					</div>;
				} else {
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="<< Prev" id="addressInfoPrevBtn" onClickFunction={()=>{ setStepObj('personalInfo')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Next >>" id="addressInfoNxtBtn" buttonClassName={actionButtonClassName + " " + addressNxtDisabled} containerClassName="container-col" />
					</div>;
				}
				break;
			case 'emailConfirmation':
				if(emailConfNxtDisabled === ""){
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="<< Prev" id="emailConfPrevBtn" onClickFunction={()=>{ setStepObj('addressInfo')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Next >>" id="emailConfNxtBtn" onClickFunction={()=>{ handleEmailConfClicked()}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
					</div>;
				} else {
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="<< Prev" id="emailConfPrevBtn" onClickFunction={()=>{ setStepObj('addressInfo')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Next >>" id="emailConfNxtBtn" buttonClassName={actionButtonClassName + " " + emailConfNxtDisabled} containerClassName="container-col" />
					</div>;
				}
				break;
			case 'studySelect':
				actionButtons = <div className={actionButtonsContainerClassName}>
	        <ActionButton display="<< Prev" id="studySelectPrevBtn" onClickFunction={()=>{ setStepObj('addressInfo')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
	        <ActionButton display="Next >>" id="studySelectNxtBtn" onClickFunction={()=>{ handleStudySelectClicked()}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
	      </div>;
				break;
			case 'studyBump':
				actionButtons = <div className={actionButtonsContainerClassName}>
					<ActionButton display="<< Prev" id="studyBumpPrevBtn" onClickFunction={()=>{ setStepObj('studySelect')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
					<ActionButton display="Next >>" id="studyBumpNxtBtn" onClickFunction={()=>{ handleStudyBumpClicked()}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
				</div>;
				break;
			case 'stripeCode':
				if(stripeNxtDisabled === ""){
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="<< Prev" id="stripeCodePrevBtn" onClickFunction={()=>{ setStepObj('studyBump')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Submit" id="stripeCodeNxtBtn" onClickFunction={()=>{ handleStripeCodeClicked()}} buttonClassName={actionButtonClassName + " " + stripeNxtDisabled} containerClassName="container-col" />
					</div>;
				} else {
					actionButtons = <div className={actionButtonsContainerClassName}>
						<ActionButton display="<< Prev" id="stripeCodePrevBtn" onClickFunction={()=>{ setStepObj('studyBump')}} buttonClassName={actionButtonClassName} containerClassName="container-col" />
						<ActionButton display="Submit" id="stripeCodeNxtBtn" buttonClassName={actionButtonClassName + " " + stripeNxtDisabled} containerClassName="container-col" />
					</div>;
				}
				break;
			default:
		}
		return actionButtons;
	}
	let formCode = <></>;

	let passwordCode = (stepValue!=="profileInfo")?
		<PasswordInfo passwordObj={passwordObj} setPasswordObj={setPasswordObj} />
		:<></>;
	let emailCode = (stepValue!=="profileInfo")?
		<Email emailObj={emailObj} setEmailObj={setEmailObj} />
		:<></>;

	let personalInfoCode = <>
		<Name
			titleObj={titleObj}      setTitleObj={setTitleObj}
			firstNameObj={firstNameObj}  setFirstNameObj={setFirstNameObj}
			middleNameObj={middleNameObj} setMiddleNameObj={setMiddleNameObj}
			lastNameObj={lastNameObj}   setLastNameObj={setLastNameObj}
			suffixObj={suffixObj}     setSuffixObj={setSuffixObj}
		/>
		<UserName userNameObj={userNameObj}	 setUserNameObj={setUserNameObj} />
		{emailCode}
		<PhoneNumber
			countryCodeObj={countryCodeObj}     setCountryCodeObj={setCountryCodeObj}
			areaCodeObj={areaCodeObj}        setAreaCodeObj={setAreaCodeObj}
			telephonePrefixObj={telephonePrefixObj} setTelephonePrefixObj={setTelephonePrefixObj}
			lineNumberObj={lineNumberObj}      setLineNumberObj={setLineNumberObj}
		/>
		<Birthdate birthdateObj={birthdateObj}       setBirthdateObj={setBirthdateObj} />
		{passwordCode}
	</>;

	let addressInfoCode = <AddressInfo
		str1Obj={str1Obj}    		setStr1Obj={setStr1Obj}
		str2Obj={str2Obj}    		setStr2Obj={setStr2Obj}
		cityObj={cityObj}    		setCityObj={setCityObj}
		stateObj={stateObj}   	setStateObj={setStateObj}
		countryObj={countryObj} setCountryObj={setCountryObj}
		zipcodeObj={zipcodeObj} setZipcodeObj={setZipcodeObj}
	 />;

 	let emailConfirmationCode = <EmailConfirmationCodeForm
		emailObj={emailObj}
		emailConfCode={emailConfCode}
		setEmailConfNxtDisabled={setEmailConfNxtDisabled}
 	 />;

	let studySelectCode = <StudySelection
	 	studySelectObj={studySelectObj}
		setStudySelectObj={setStudySelectObj} />;

	let studyBumpCode = <></>;

	let stripeCode = <></>;

	switch(stepObj){
		case 'profileInfo':
			formCode = <div className={mainContainerClassName}>
				{personalInfoCode}
				{addressInfoCode}
				{getActionButtons()}
			</div>;
			break;
		case 'addressInfo':
			formCode = <div className={mainContainerClassName}>
				{addressInfoCode}
				{getActionButtons()}
			</div>;
			break;
		case 'emailConfirmation':
			formCode = <div className={mainContainerClassName}>
				{emailConfirmationCode}
				{getActionButtons()}
			</div>
			break;
		case 'studySelect':
			formCode = <div className={mainContainerClassName}>
				{studySelectCode}
				{getActionButtons()}
	      </div>;
			break;
		case 'studyBump':
			formCode = <div className={mainContainerClassName}>
				{studyBumpCode}
				{getActionButtons()}
			</div>;
			break;
		case 'stripeCode':
			formCode = <div className={mainContainerClassName}>
				{stripeCode}
				{getActionButtons()}
			</div>;
			break;
		default: // personalInfo
			formCode = <div className={mainContainerClassName}>
				{personalInfoCode}
				{getActionButtons()}
			</div>;
	}

	return (formCode);
}


export default Profile;
