import React, { useState } from 'react';
import TextInput from '../formElements/TextInput';
import ActionButton from '../formElements/ActionButton';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';
import {
	validateRequiredValue
} from '../../lib/formElementHelperFunctions';
import {
	createRandomString
} from '../../lib/helperFunctions';
import './Community.css';

const getStarterObj = (reply) => {
	if(reply && reply.text !== ''){
		return {
			value: reply.text,
			errorMessage: '',
			errorClassName: 'no-error',
			valid:true
		}
	} else {
		return {
			value: '',
			errorMessage: '',
			errorClassName: 'no-error',
			valid:false
		};
	}
};

const ReplyForm = ({ user, conversId, comment, reply, token, setShowReplyForm, callBack }) => {
	const [ replyTextObj, setReplyTextObj ] = useState(getStarterObj(reply));
	const conversationId = conversId;
	const replyFormId = (reply)?`conversation-reply-${reply._id}`:(comment)?`conversation-reply-${comment._id}`:`conversation-reply-${conversationId}`;
	const replyFormSubmitButtonId = (reply)?`replySubmit-${reply._id}`:(comment)?`commentSubmit-${comment._id}`:`commentSubmit-${conversationId}`;
	const submitButtonClassName = 'button-action button-reply-submit';
	const replyFormMainContainerClassName = 'container-reply-form-main';
	const handleReplyTextChanged = (val, obj, callBack) => {
		callBack(validateRequiredValue(val, obj));
	};

	const buildId = () => {
		let replies = (comment?.replies  && Array.isArray(comment.replies))?comment.replies:[];
		let replyIDs = [];
		replies.forEach((reply) => {
			replyIDs.push(reply._id);
		});
		let newID = createRandomString(13);
		while(replyIDs.includes(newID)){
			newID = createRandomString(13);
		}
		return newID;
	}

	let submitButton = <ActionButton
		display="Submit"
		id={replyFormSubmitButtonId}
		onClickFunction={()=>{
			if(replyTextObj.valid){
				let path = (reply)?'update/reply':'create/reply';
				let replyObj = {};
				replyObj['_id'] = (reply?._id)?reply._id:buildId();
				replyObj['userName'] = (reply?.userName)?reply.userName:user.name.userName;
				replyObj['likes'] = (reply?.likes)?reply.likes:[];
				replyObj['deleted'] = (reply?.deleted)?reply?.deleted:false;
				replyObj['text'] = replyTextObj.value;
				let dataObj = {};
				dataObj['token'] = token;
				dataObj['replyObj'] = replyObj;
				dataObj['conversationId'] = conversationId;
				dataObj['commentId'] = comment._id;
				callWebServicePost(dataObj, path, (results) => {
					if(Array.isArray(results)){
						for(let arrInd=0; arrInd<results.length; arrInd++){
							if(results[arrInd].message.result.modifiedCount > 0){
								setShowReplyForm(false);
								if(callBack){
									callBack(replyObj);
								}
							}
						}
					} else {
						if(results.message.result.modifiedCount > 0){
							setShowReplyForm(false);
							if(callBack){
								callBack(replyObj);
							}
						}
					}
				});
			} else {
				alert('The reply text cannot be blank. Please enter a value or cancel your request.');
			}
		 }}
		buttonClassName={submitButtonClassName}
		containerClassName="container-col" />;
	let textinput = <TextInput
		id={replyFormId}
		inputClassName='soft-border shadow-bottom-right'
		value={replyTextObj.value}
		size='25'
		errorMessage={replyTextObj.errorMessage}
		errorClassName={replyTextObj.errorClassName}
		containerClass="form-data"
		onChangeFunct={ (e) => {
			handleReplyTextChanged(e.target.value, replyTextObj, (obj)=>{
				setReplyTextObj(obj);
			});
		} }
	/>
	let returnCode = <div className={replyFormMainContainerClassName}>
		<div><label>Reply {textinput}</label></div>
		<div>{submitButton}</div>
	</div>

	return(returnCode);
};

export default ReplyForm;
