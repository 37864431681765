import React from 'react';
import './formElements.css';
import {
  isObject
} from '../../lib/helperFunctions';

export default function SelectOption({ value, displayValue, optionClassName }){
	let optionValue = (isObject(value))?JSON.stringify(value):value;
	return(
    <option key={displayValue} value={optionValue}	className={optionClassName}>
			{displayValue}
		</option>
	);

}
