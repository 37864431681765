import React, { useState, useEffect } from 'react';
import TextInput from '../formElements/TextInput';
import ActionButton from '../formElements/ActionButton';

const EmailConfirmationCodeForm = ({ emailObj, emailConfCode, setEmailConfNxtDisabled }) => {
	const [ code, setCode ] = useState();
	const actionButtonClassName = "button-action button-code-check-submit";
	const actionButtonContainerClassName = "button-code-check-submit-container";
	const textInput = <TextInput
		id='emailConfirmationCode'
		type='text'
		value={code}
		inputClassName='soft-border shadow-bottom-right'
		maxlength='9'
		size='13'
		errorMessage=''
		errorClassName=''
		containerClass='form-data'
		onChangeFunct={(e)=>{ setCode(e.target.value) }}
	/>;
	const submitButton = <ActionButton
		display="Check Code"
		id="check-code-submit-button"
		onClickFunction={()=>{checkCode()}}
		buttonClassName={actionButtonClassName}
		containerClassName='container-col'
	/>;
	const checkCode = () => {
		if(code === emailConfCode){
			setEmailConfNxtDisabled('');
		}
	};

	let returnCode = <div>
		<h1>EmailConfirmationCodeForm</h1>
		<h3>Please check {emailObj?.value} to see if you received a code. If it is not there, please check your spam/junk folder.</h3>

		{textInput}
		<div className={actionButtonContainerClassName}>
			{submitButton}
		</div>
	</div>;
	return(returnCode);
}

export default EmailConfirmationCodeForm;
/*
<h2>{emailConfCode}</h2>
<h4>{emailObj?.value}</h4>
<h5>{emailConfCode} = {code} >> {valIsEqual}</h5>
*/
