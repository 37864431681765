import React, { useState, useEffect } from 'react';
import {
	handleBirthDateChange
} from '../../lib/formElementHelperFunctions';
import TextInput from '../formElements/TextInput';

const Birthdate = ({
	birthdateObj,       setBirthdateObj
 }) => {
	 const mainContainerClassName = 'container-birthdate';
	 const birthdateContainerClassName = 'container-personal-info-birthdate';
	 const birthdateLabelClassName = 'form-title';
	 const birthdateClassName = 'container-birthdate';
	 const formItemContainerClassName = 'form-item-container';

  return (
    <div className={mainContainerClassName}>
			<div className={birthdateContainerClassName}>
				<div className={birthdateClassName}>
					<div className={formItemContainerClassName}>
						<div className={birthdateLabelClassName}>
							<label htmlFor="bDate"> Birthdate: </label>
						</div>
						<TextInput
							id="bDate"
							type="date"
							placeholder=''
							inputClassName='soft-border shadow-bottom-right'
							min="1900-01-01"
							max="2100-12-31"
							value={birthdateObj.value}
							size='25'
							errorMessage={birthdateObj.errorMessage}
							errorClassName={birthdateObj.errorClassName}
							containerClass="form-data"
							onChangeFunct={(e) => handleBirthDateChange(e.target.value, birthdateObj, (obj) => setBirthdateObj(obj))}
						/>
					</div>
				</div>
			</div>
    </div>
  );

}

export default Birthdate;
