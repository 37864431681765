import React from 'react';
import './Footer.css';
import {

} from '../../lib/webserviceFunctions';
import ActionButton from '../formElements/ActionButton';

const Footer = ({isLoggedIn}) => {
	const mainContainerClassName = 'container-footer-main';
	const copyrightClassName = "small-copyright";
	const footerTitleClassName = "footer-title";
	const footerMenuClassName = "footer-menu-container";
	const footerMenuButtonClassName = "footer-menu-item";
	const footerMenuItemContainerClassName = "footer-menu-item-container";
	return (
		<div className={mainContainerClassName}>
			<div className={copyrightClassName}>&#169; 2023</div>
			<div className={footerTitleClassName}>Disciple Maker Bible Study</div>
			<div className={footerMenuClassName}>
				<ActionButton display="About Us" id="aboutUsBtn" onClickFunction={()=>{ alert('We are Disciple Makers!') }} buttonClassName={footerMenuButtonClassName} containerClassName={footerMenuItemContainerClassName} />
				<ActionButton display="Contact Us" id="contactUsBtn" onClickFunction={()=>{ alert('Please send us an email: contactus@jmlatham.com.') }} buttonClassName={footerMenuButtonClassName} containerClassName={footerMenuItemContainerClassName} />
			</div>
		</div>
	)
}

export default Footer;
