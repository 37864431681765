import React from 'react';
import TextInput from '../formElements/TextInput';
import {
	handleEmailChange
} from '../../lib/formElementHelperFunctions';

export default function Email({
  emailObj, setEmailObj
}) {
  const mainContainerClassName = 'container-email-address';
  const emailItemContainerClassName = 'form-item-container';
  const emailLabelClassName = 'form-title';
  const emailInputContainerClassName = 'form-data';
  const emailInputClassName = 'soft-border shadow-bottom-right';
  const asteriskClassName = 'required-item';

  return(
    <div className={mainContainerClassName}>
      <div className={emailItemContainerClassName}>
        <div className={emailLabelClassName}>
          <label htmlFor="email"> <span className={asteriskClassName} >*</span> Email Address: </label>
        </div>
        <TextInput
          id="email"
          type="text"
          placeholder="name@email.com"
          inputClassName={emailInputClassName}
          value={emailObj.value}
          size='25'
          errorMessage={emailObj.errorMessage}
          errorClassName={emailObj.errorClassName}
          containerClass={emailInputContainerClassName}
          onChangeFunct={(e) => handleEmailChange(e.target.value, emailObj, (obj)=>setEmailObj(obj))}
        />
      </div>
    </div>
  );
}
