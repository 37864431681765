import React, { useState } from 'react';
import ActionButton from '../formElements/ActionButton';
import Likes from '../pageElements/Likes';
import ReplyForm from './ReplyForm';
import {
	callWebServicePost
} from '../../lib/webserviceFunctions';

const Reply = ({ reply, conversationId, comment }) => {
	const [ showForm, setShowForm ] = useState(false);
	const [ replyText, setReplyText ] = useState(reply.text);
	const [ likesArray, setLikesArray ] = useState(reply.likes);
	const [ deleted, setDeleted ] = useState(reply.deleted);
	const sessionId = JSON.parse(sessionStorage.getItem('DMBS')).token;
	const userObj = JSON.parse(sessionStorage.getItem('DMBS')).user;
	const updateReply = (reply) => {
		setReplyText(reply.text);
	};
	const replyForm = <ReplyForm conversId={conversationId} comment={comment} reply={reply} user={userObj} token={sessionId} setShowReplyForm={setShowForm} callBack={updateReply}/>;
	const likeTheReply = (replyId) => {
		const dataObj = {token:sessionId, conversationId:conversationId, commentId:comment._id, reply:reply, user:userObj.email};
		const path = 'toggle/reply/like';
		callWebServicePost(dataObj, path, (results)=>{ sessionStorage.setItem('ReplyLikeResults',JSON.stringify(results)) });
		let tempArray = [...likesArray];
		let index = likesArray.indexOf(userObj.email);
		if(index >= 0){
			tempArray.splice(index, 1);
		} else {
			tempArray.push(userObj.email);
		}
		setLikesArray(tempArray);
	};
	const replyTextClassName = 'reply-text';
	const replyUsernameClassName = 'reply-username';
	const replyDateCreatedClassName = 'reply-datecreated';
	const replyUpdateClassName = "button-action button-reply-update";
	const replyUpdateCancelClassName = 'button-action button-reply-update-cancel';
	const replyDeleteClassName = 'button-action button-reply-delete';
	const replyMainContainer = 'container-reply-main';
	const replyLikeContainerClassName = '';
	const replyLikeButtonClassName = 'reply-likes-button';

	let updateButton = (showForm)?
	<ActionButton
		display="Cancel" id={`updateBtn_${reply._id}`}
		onClickFunction={ ()=>{ setShowForm(false); } }
		buttonClassName={replyUpdateCancelClassName} containerClassName={`container-col`} />:
	<ActionButton
		display="Update" id={`updateBtn_${reply._id}`}
		onClickFunction={ ()=>{ setShowForm(true); } }
		buttonClassName={replyUpdateClassName} containerClassName={`container-col`} />;

	return(
		<div key={`${conversationId}_reply_${reply._id}`} className={(deleted)?'display-none':replyMainContainer}>
			<div className={replyTextClassName}>
				{replyText}
			</div>
			<div className={replyUsernameClassName}>
				{reply.userName}
			</div>
			<div className={replyDateCreatedClassName}>
				{reply.dateCreated}
			</div>
			<div className='container-row'>

				<ActionButton
					display="Delete" id={`deleteBtn_${reply._id}`}
					onClickFunction={
						()=>{
							let text = "Are you sure you want to delete the following reply? \n" + reply.text;
						  if (window.confirm(text) === true) {
								const dataObj = {token:sessionId, conversationId:conversationId, commentId:comment._id, reply:reply};
								const path = 'delete/reply';
								callWebServicePost(dataObj, path, (commPermissions) => {
									if(commPermissions){
										sessionStorage.setItem('replyDelete',JSON.stringify(commPermissions));
									}
								});
								setDeleted(!deleted);
						  }
						}
					}
					buttonClassName={replyDeleteClassName} containerClassName={`container-col ${(!showForm)?'':'display-none'}`} />
					{updateButton}
					<div className={(showForm)?'display-flex':'display-none'}>{replyForm}</div>
					<Likes
						likes={likesArray}
						likeId={reply._id}
						toggleLike={
							()=>{
								likeTheReply(reply._id);
							}
						}
						containerClass={replyLikeContainerClassName}
						likeButtonClassName={replyLikeButtonClassName}
					/>
			</div>
		</div>
	);
};

export default Reply;
