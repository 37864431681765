import React from 'react';
import {
	handleCountryChange,
	handleStateChange,
	handleCityChange,
	handleStr1Change,
	handleStr2Change,
	handleZipCodeChange
} from '../../lib/formElementHelperFunctions';
import TextInput from '../formElements/TextInput';
import StateSelect from '../formElements/StateSelect';

const AddressInfo = ({
	str1Obj,    setStr1Obj,
  str2Obj,    setStr2Obj,
  cityObj,    setCityObj,
  stateObj,   setStateObj,
  countryObj, setCountryObj,
  zipcodeObj, setZipcodeObj
 }) => {
	 const mainContainerClassName = 'container-user-address';
   const countryItemContainerClassName = 'form-item-container';
   const countryLabelClassName = 'form-title';
   const stateItemContainerClassName = 'form-item-container';
   const stateLabelClassName = 'form-title';
   const cityItemContainerClassName = 'form-item-container';
   const cityLabelClassName = 'form-title';
   const street1ItemContainerClassName = 'form-item-container';
   const street1LabelClassName = 'form-title';
   const street2ItemContainerClassName = 'form-item-container';
   const street2LabelClassName = 'form-title';
   const zipItemContainerClassName = 'form-item-container';
   const zipLabelClassName = 'form-title';


   const usaArray = ['united states', 'united states of america', 'usa', 'us'];
   const stateFormElement = (!countryObj?.value || countryObj?.value.trim() === '' || usaArray.includes(countryObj?.value.trim().toLowerCase()))?
     <StateSelect
       value={stateObj.value}
       onChangeFunct={(e) => handleStateChange(e.target.value, stateObj, (obj)=>setStateObj(obj))}
       selectClassName={'state-select soft-border shadow-bottom-right'}
       optionClassName={'state-select-option'}
       containerClass={'form-data'}
     />:
     <TextInput
       id="state"
       type="text"
       placeholder='state name'
       inputClassName='soft-border shadow-bottom-right'
       value={stateObj.value}
       size='25'
       errorMessage={stateObj.errorMessage}
       errorClassName={stateObj.errorClassName}
       containerClass="form-data"
       onChangeFunct={(e) => handleStateChange(e.target.value, stateObj, (obj)=>setStateObj(obj))}
     />

   return(
     <div className={mainContainerClassName}>
       <div className={countryItemContainerClassName}>
         <div className={countryLabelClassName}><label htmlFor="country"> Country: </label></div>
         <TextInput
           id="country"
           type="text"
           placeholder='country name'
           inputClassName='soft-border shadow-bottom-right'
           value={(!countryObj.value || countryObj.value.trim() ==='')?'USA':countryObj.value}
           size='25'
           errorMessage={countryObj.errorMessage}
           errorClassName={countryObj.errorClassName}
           containerClass="form-data"
           onChangeFunct={(e) => handleCountryChange(e.target.value, countryObj, (obj)=>setCountryObj(obj))}
         />
       </div>
       <div className={stateItemContainerClassName}>
         <div className={stateLabelClassName}><label htmlFor="state"> State: </label></div>
         {stateFormElement}
       </div>
       <div className={cityItemContainerClassName}>
         <div className={cityLabelClassName}><label htmlFor="city"> City: </label></div>
         <TextInput
           id="city"
           type="text"
           placeholder='city name'
           inputClassName='soft-border shadow-bottom-right'
           value={cityObj.value}
           size='25'
           errorMessage={cityObj.errorMessage}
           errorClassName={cityObj.errorClassName}
           containerClass="form-data"
           onChangeFunct={(e) => handleCityChange(e.target.value, cityObj, (obj)=>setCityObj(obj))}
         />
       </div>
       <div className={street1ItemContainerClassName}>
         <div className={street1LabelClassName}><label htmlFor="street_1"> Street: </label></div>
         <TextInput
           id="street_1"
           type="text"
           placeholder='street address'
           inputClassName='soft-border shadow-bottom-right'
           value={str1Obj.value}
           size='25'
           errorMessage={str1Obj.errorMessage}
           errorClassName={str1Obj.errorClassName}
           containerClass="form-data"
           onChangeFunct={(e) => handleStr1Change(e.target.value, str1Obj, (obj)=>setStr1Obj(obj))}
         />
       </div>
       <div className={street2ItemContainerClassName}>
         <div className={street2LabelClassName}><label htmlFor="street_2"> Extra Street: </label></div>
         <TextInput
           id="street_2"
           type="text"
           placeholder='Suite 113'
           inputClassName='soft-border shadow-bottom-right'
           value={str2Obj.value}
           size='25'
           errorMessage={str2Obj.errorMessage}
           errorClassName={str2Obj.errorClassName}
           containerClass="form-data"
           onChangeFunct={(e) => handleStr2Change(e.target.value, str2Obj, (obj)=>setStr2Obj(obj))}
         />
       </div>
       <div className={zipItemContainerClassName}>
         <div className={zipLabelClassName}><label htmlFor="zipcode"> Zip Code: </label></div>
         <TextInput
           id="zipcode"
           type="text"
           placeholder='12345'
           inputClassName='soft-border shadow-bottom-right'
           value={zipcodeObj.value}
           size='25'
           maxLength='10'
           errorMessage={zipcodeObj.errorMessage}
           errorClassName={zipcodeObj.errorClassName}
           containerClass="form-data"
           onChangeFunct={(e) => handleZipCodeChange(e.target.value, zipcodeObj, (obj)=>setZipcodeObj(obj))}
         />
       </div>
     </div>
   );
}

export default AddressInfo;
